import React, { useState } from "react";
import { Link } from "react-router-dom";

//external libararies
import { Avatar, Badge } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

//Stack assets
import { accountList } from "../utilites/constants";
import MainMenu from "../mainMenu/MainMenu";
import logo from "../../assets/images/logo.png";
import { useIsLoggedInQuery } from "../../graphql/operations/queries/IsLoggedIn";
import { useGetTechCoursesQuery } from "../../graphql/operations/queries/getTechCourses";
import { useGetCareerPathsQuery } from "../../graphql/operations/queries/getCareerPaths";
import UserAvatar from "../userAvatar/UserAvatar";
import { useGetCartItems } from "../../graphql/operations/queries/getCartItems";

const Navbar = (props) => {
  //queries
  const {
    data: { isLoggedIn },
  } = useIsLoggedInQuery();
  const {
    data: { cart = [] },
  } = useGetCartItems();

  const {
    dataWithRoutes: { stacks: techStackList = [] } = {},
    loading: techStackLoading,
    error: techStackError,
  } = useGetTechCoursesQuery();
  const {
    dataWithRoutes: { careerpaths: careerPathList = [] } = {},
    loading: careerPathLoading,
    error: careerPathError,
  } = useGetCareerPathsQuery();

  //navbar config
  const classes = useStyles();
  const theme = useTheme();
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDrawer, setOpenDrawer] = useState(false);

  const tabs = (
    <>
      <MainMenu
        title="Tech Courses"
        variant="menu"
        menuProps={{
          data: techStackList,
          loading: techStackLoading,
          error: techStackError,
        }}
      />
      <MainMenu
        title="Career Paths"
        variant="link-menu"
        linkProps={{ to: "/careerpath" }}
        menuProps={{
          data: careerPathList,
          loading: careerPathLoading,
          error: careerPathError,
        }}
      />
      <MainMenu
        title="For Enterprise"
        linkProps={{ to: "/enterprise" }}
        variant="link"
      />
      <MainMenu
        title="View Plans"
        linkProps={{ to: "/pricing-plan" }}
        variant="link"
      />
      <MainMenu
        title={isLoggedIn ? (matches ? "Account" : "") : "Login"}
        icon={<UserAvatar type={"mini"} />}
        variant={isLoggedIn ? "menu" : "link"}
        linkProps={{ to: "/login" }}
        menuProps={{
          data: isLoggedIn ? accountList : [],
          type: "account",
        }}
      />
      {cart.length > 0 && (
        <div>
          <Link to="/checkout" className={`${classes.cartIconLink}`}>
            <Badge
              badgeContent={cart.length}
              classes={{
                root: classes.badgeRoot,
                badge: classes.badge,
              }}
            >
              <ShoppingCartIcon className={classes.cartIcon} />
            </Badge>
            {matches && <p>Cart</p>}
          </Link>
        </div>
      )}
    </>
  );

  const drawer = (
    <>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        onOpen={() => {
          setOpenDrawer(true);
        }}
        className={classes.drawer}
      >
        <div className={classes.toolbarMargin} />
        <Avatar src={logo} className={classes.mobileLogo} />
        <div className={classes.toolbarMargin} />
        <div className={classes.mobileWrapper}>{tabs}</div>
      </SwipeableDrawer>
      <IconButton
        disableRipple
        onClick={() => {
          setOpenDrawer(!openDrawer);
        }}
        className={classes.drawerIconContainer}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </>
  );

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position="fixed" elevation={0}>
        <Toolbar disableGutters className={classes.mainHeader}>
          <Link to="/" className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="stackwiseR logo" />
          </Link>
          {matches ? (
            drawer
          ) : (
            <div className={classes.tabsContainer}>{tabs}</div>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  mobileWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& >div": {
        width: "100%",

        "& > a , & > .MuiButton-root": {
          textDecoration: "none",
          color: "white",
          margin: 0,
          textTransform: "none",
          display: "flex",
          textAlign: "left",
          justifyContent: "start",
          fontSize: "13px",
          letterSpacing: "1.4px",
          padding: "15px 0 15px 14%",
          borderRadius: "0",
          borderTop: "solid 1px #5f5f5f14",
          borderBottom: "solid 1px #5f5f5f14",
        },
      },
    },
  },
  mobileLogo: {
    alignSelf: "center",
    width: "200px",
    borderRadius: "0",
  },

  //NOTE The rest
  mainHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabsContainer: {
    display: "flex",
    alignItems: "center",
    "& a": {
      textDecoration: "none",
      color: "white",
      fontFamily: "OpenSans",
      paddingRight: "25px",
      textTransform: "capitalize",
      fontSize: "14px",
    },
    "& .freeTrial": {
      padding: "9px",
      border: "1px solid white",
    },
    "& .profileIcon": {
      borderLeft: "2px solid white",
      paddingLeft: "14px",
    },

    "& button": {
      textTransform: "capitalize",
    },
  },

  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  tabContainer: {
    marginLeft: "auto",
    marginRight: "10px",
  },
  tab: {
    ...theme.typography.tab,
    letterSpacing: "0.02em",
    minWidth: 10,
    marginLeft: "5px",
  },
  cartIconLink: {
    marginLeft: "20px",
    transition: "0.5s ease",
    position: "relative",
    textDecoration: "none",
    "&:hover": {
      color: "#ffffff9e",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: "14%",
      color: "#fff",
      display: "flex",

      "& .MuiBadge-root": {
        marginRight: "15px",
      },
    },
  },
  cartIcon: {},
  badge: {
    animation: "$MoveUpDown 5s ease infinite",
    webkitAnimation: "$MoveUpDown 6s ease infinite",
    backgroundColor: "#212033",
    border: "solid 1px #ffffff26",
    height: " 100%",
    width: "100%",
    borderRadius: "50px",
  },
  "@keyframes MoveUpDown": {
    "0%": {
      boxShadow: "0 0 6px 9px#2994e000",
    },
    "50%": {
      boxShadow: "0 0 6px 9px#2994e046",
    },
    "100%": {
      boxShadow: "0 0 6px 9px#2994e000",
    },
  },

  drawer: {
    "&&": {
      "& .maindrdwWr": {
        position: "inherit !important",
        willChange: "transform !important",
        zIndex: " 9999 !important",
        width: "100% !important",
        top: " inherit !important",
        left: " 0px !important",
        transform: " none !important",
      },
      "& .MuiDrawer-paperAnchorRight": {
        width: " 260px !important",
        backgroundColor: "#1c1b2a !important",
      },
    },
  },
  logo: {
    position: "relative",
    height: "3em",
    paddingLeft: "5px",
    [theme.breakpoints.down("md")]: {
      height: "2.5em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2.2em",
    },
  },
  logoContainer: {
    position: "relative",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIconContainer: {
    marginLeft: "auto",
    color: "white",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    width: "40px",
    height: "40px",
  },
  appBar: {
    // zIndex: '10000',
    backgroundColor: "#161525",
    padding: "0px 40px",
    // position: 'relative',
  },
  headerDb: {
    backgroundColor: "white",
  },
}));

export default Navbar;
