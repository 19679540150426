import { APPLICATION_ENVS, CURRENT_ENV } from "./../app.config";
import React, { lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { Suspense } from "react";
import { handleJwt } from "../graphql/config/auth";
import ReactGA from "react-ga";

import PageLoader from "../components/loading/PageLoader";
//componenets
const ActiveSubscriberRoute = lazy(() => import("../components/ActiveSubscriberRoute"));
const CareerpathLanding = lazy(() => import("../containers/careerpathLanding/CareerpathLanding"));
// import Landing from '../containers/landing/individual/Landing';
const ComingSoon = lazy(() => import("../components/comingSoon/ComingSoon"));
const CookiePolicyDetail = lazy(() => import("../containers/cookiePolicyDetail/CookiePolicyDetail"));
const EventRegistration = lazy(() => import("../containers/eventRegistration/EventRegistration"));
const PdfViewer = lazy(() => import("../components/pdfViewer/PdfViewer"));
const SecureRoute = lazy(() => import("../components/SecureRoute"));

//container
const MentorRequest = lazy(() => import("../containers/mentorRequest/MentorRequest"));
const Account = lazy(() => import("../containers/account/Account"));
const Landing = lazy(() => import("../containers/landing/individual/Landing"));
const Events = lazy(() => import("../containers/events/Events"));
const Event = lazy(() => import("../containers/event/Event"));
const FreeCourse = lazy(() => import("../containers/freeCourse/FreeCourse"));
const BusinessLanding = lazy(() => import("../containers/landing/business/BusinessLanding"));
const Course = lazy(() => import("../containers/course/Course"));
const CareerPath = lazy(() => import("../containers/careerPath/CareerPath"));
const PricingPlan = lazy(() => import("../containers/pricingPlan/PricingPlan"));
const SignIn = lazy(() => import("../containers/signIn/SignIn"));
const Signup = lazy(() => import("../containers/signUp/SignUpView"));
const Quiz = lazy(() => import("../containers/quizz/Quiz"));
const TechCourse = lazy(() => import("../containers/techCourse/TechCourse"));
const Learning = lazy(() => import("../containers/learning/Learning"));
const ProjectIntroduction = lazy(() => import("../containers/projectIntroduction/ProjectIntroduction"));
const CareerPathExam = lazy(() => import("../containers/courseAssignment/CourseAssignment"));
const ProjectLibrary = lazy(() => import("../containers/project/ProjectLibrary"));
const CareerService = lazy(() => import("../containers/careerService/CareerService"));
const Checkout = lazy(() => import("../containers/checkout/Checkout"));
const Subscribe = lazy(() => import("../containers/subscribe/Subscribe"));
const ContactUs = lazy(() => import("../containers/contactUs/ContactUs"));
const Career = lazy(() => import("../containers/career/Career"));
const JobDescription = lazy(() => import("../containers/career/jobDescription/JobDescription"));
const About = lazy(() => import("../containers/about/About"));
const Community = lazy(() => import("../containers/community/Community"));
const ClassPage = lazy(() => import("../containers/community/communityRoom/CommunityRoom"));
const ForgotPassword = lazy(() => import("../containers/forgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../containers/resetPassword/ResetPassword"));
const Faq = lazy(() => import("../containers/faq/Faq"));
const Request = lazy(() => import("../containers/faq/request/Request"));
const CustomerStories = lazy(() => import("../containers/customerStories/CustomerStories"));
const TermAndCondition = lazy(() => import("../containers/termAndCondition/TermAndCondition"));
const PrivacyPolicy = lazy(() => import("../containers/privacyPolicy/PrivacyPolicy"));
const MembershipIntro = lazy(() => import("../containers/membershipIntro/MembershipIntro"));
const Demo = lazy(() => import("../containers/demo/Demo"));
const Jmp = lazy(() => import("../containers/jmp/Jmp"));

//components
const Exercise = lazy(() => import("../components/exercise/Exercise"));
const ProjectAnswer = lazy(() => import("../components/answer/ProjectAnswer"));
const NotFound = lazy(() => import("../components/notFound/NotFound"));

const [_, getJwt, __, ___] = handleJwt();
const { userId = "null", usertype, planId, subscriptionStatus, chargeOptionId } = getJwt() ? JSON.parse(getJwt()) : {};

const BaseRoutes = () => {
  let { pathname: path } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  if ([APPLICATION_ENVS.PROD].includes(CURRENT_ENV) ? true : false) {
    ReactGA.initialize("UA-195406109-1", {
      gaOptions: {
        userId: userId,
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <SecureRoute path="/account" component={() => <Account />} />
          <Route exact path="/" component={Landing} />
          <Route exact path="/enterprise" component={BusinessLanding} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/login/forgot-password" component={ForgotPassword} />
          <Route exact path="/login/reset-password" component={ResetPassword} />
          <Route exact path="/signup" component={Signup} />

          <Route path="/about" exact component={() => <About />} />
          <Route path="/subscribe" exact component={Subscribe} />
          <Route path="/faq" exact component={() => <Faq />} />
          <Route path="/customers-stories" exact component={() => <CustomerStories />} />
          <Route path="/faq/submit-request" exact component={() => <Request />} />
          <Route path="/pricing-plan" exact component={() => <PricingPlan />} />
          <Route path="/individual-membership-intro" exact component={() => <MembershipIntro />} />
          <Route path="/enterprise-membership-intro" exact component={() => <MembershipIntro />} />
          <Route path="/careerpaths/:id/:slug" exact component={() => <CareerPath />} />
          <Redirect from="/careerpath/:id/:slug" to="/careerpaths/:id/:slug" />
          <Route path="/careerpaths" component={CareerpathLanding} />
          <Redirect from="/careerpath" to="/careerpaths" />
          <Route path="/free-courses/" exact component={() => <FreeCourse />} />
          <Route path="/techstack/" exact component={() => <TechCourse />} />
          <Route path="/techstack/:id/:slug" exact component={TechCourse} />
          <Route path="/exercise" exact component={Exercise} />
          <Route path="/course/:id/:courseTitle" exact component={() => <Course />} />
          <Route path="/course" exact>
            {" "}
            <Redirect to="/" />
          </Route>
          <Route path="/terms-and-conditions" exact component={() => <TermAndCondition />} />
          <Route path="/privacy-policy" exact component={() => <PrivacyPolicy />} />
          <Route path="/cookie-policy" exact component={() => <CookiePolicyDetail />} />
          <Route path="/career-service" exact component={CareerService} />
          <Route path="/demo" exact component={Demo} />
          <Route path="/project-introduction" exact component={() => <ProjectIntroduction />} />
          <Route path="/project-introduction/:id/:slug" exact component={() => <ProjectIntroduction />} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/job-mentoring-programme" exact component={Jmp} />
          <Route path="/events" exact component={Events} />
          <Route path="/events/:id/:slug" exact component={Event} />
          <Route path="/events/registration" exact component={EventRegistration} />
          <Redirect from="/event" to="/events" />
          <Route path="/mentor-request" exact component={MentorRequest} />

          <SecureRoute path="/project-library" exact component={() => <ProjectLibrary />} />

          <SecureRoute path="/answer/:id" exact component={() => <ProjectAnswer />} />

          <SecureRoute path="/careerExam" exact component={() => <CareerPathExam />} />

          <SecureRoute path="/quiz" exact component={() => <Quiz />} />
          <SecureRoute path="/career" exact component={() => <Career />} />
          <SecureRoute path="/career/:slug" exact component={() => <JobDescription />} />
          <SecureRoute path="/community" exact component={() => <Community />} />
          <SecureRoute path="/community/:slug" exact component={() => <ClassPage />} />
          <SecureRoute path="/checkout" exact component={() => <Checkout />} />

          <Route path="/pdf" exact component={PdfViewer} />
          <ActiveSubscriberRoute path="/interview-resource/:id/:title" exact component={PdfViewer} />

          <ActiveSubscriberRoute
            path="/course/:courseId/:courseTitle/learn/topic/:topicId"
            exact
            component={Learning}
          />
          <ActiveSubscriberRoute path="/course/:courseId/:courseTitle/learn/topic/" exact component={Learning} />
          <Redirect from="/course/:courseId/:courseTitle/learn" to="/course/:courseId/:courseTitle/learn/topic/" />

          <Route path="/coming-soon" component={ComingSoon} />
          <Route path="/400" component={() => <NotFound type="400" />} />
          <Route path="/401" component={() => <NotFound type="401" />} />
          <Route path="/403" component={() => <NotFound type="403" />} />
          <Route path="/404" component={() => <NotFound type="404" />} />
          <Route path="/500" component={() => <NotFound type="500" />} />
          <Route path="/502" component={() => <NotFound type="502" />} />
          <Route path="/503" component={() => <NotFound type="503" />} />
          <Route path="/504" component={() => <NotFound type="504" />} />
          <Route exact path="*" component={() => <NotFound type="404" />} />
        </Switch>
      </Suspense>
    </>
  );
};

export default BaseRoutes;
