import { cache } from "./cache";
import * as Cookies from "js-cookie";
import { HttpLink } from "apollo-boost";
import { ApolloClient } from "@apollo/client";
import { errorLink } from "./globalErrorHandler";
import { resolvers, typeDefs } from "./resolvers";
import { setContext } from "@apollo/client/link/context";

//jwt
import { JWT } from "./auth";

//env
import { API_URL } from "../../app.config";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  const token = Cookies.get(JWT) && JSON.parse(Cookies.get(JWT)).token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// const httpLink = new HttpLink({ uri: 'http://165.22.123.62:8443/' });
// const httpLink = new HttpLink({ uri: "https://api.stackwisr.com" });
// const httpLink = new HttpLink({ uri: "https://api-staging.stackwisr.com" });

const httpLink = new HttpLink({ uri: API_URL });

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
  typeDefs,
  resolvers,
  name: "StackwisR Application",
  connectToDevTools: true,
});
