import styled from 'styled-components';

export const LoaderContainer = styled.div`
    width: 86.93px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:${p => p.size === "md" ? "50px auto" : p.size === "s" ? "20px auto" : "auto"};
    opacity:0.5;

`;

export const Loader = styled.div`
    border: ${p => p.size === "md" ? "20px solid #f3f3f3" : p.size === "s" ? "10px solid #f3f3f3" : "6px solid #f3f3f3"} ;
    border-radius: 50%;
    border-top: ${p => p.size === "md" ? "20px  solid #3498db" : p.size === "s" ? "9px  solid #3498db" : "5px  solid #3498db"};
    width: ${p => p.size === "md" ? "30px" : p.size === "s" ? "15px" : "7px"};
    height: ${p => p.size === "md" ? "30px" : p.size === "s" ? "15px" : "7px"};
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;


export const LogoLoaderContainer = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    opacity:0.5;


    .App_logo {
        height: ${p => p.size === "md" ? "10vmin" : p.size === "s" ? "2vims" : "4vmin"};
        width: ${p => p.size === "md" ? "10vmin" : p.size === "s" ? "2vims" : "4vmin"};
        pointer-events: none;
    }

    @media (prefers-reduced-motion: no-preference) {
    .App_logo {
        animation: App-logo-spin infinite 20s linear;
        }
    }
    @keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


`;