import PropTypes from "prop-types";
import React from 'react'
import styled from 'styled-components';
import { Button as MuButton, makeStyles } from '@material-ui/core'
import { Spinner } from "../loading";


const Wrapper = styled.div`
   &&{ 
    button,a{
        color: #fff;
        background: #39A3FF;
        background-image:  linear-gradient(to bottom, #3590e1 0%, #1b4871 100%) ;
        padding-left: 20px;
        padding-right: 20px;
        text-transform: none;
        letter-spacing: 0.8px;
        border-radius: 4px;
        transition: box-shadow 0.15s ease,transform 0.15s ease;
        will-change: box-shadow,transform;

        :hover{
             cursor: pointer;
             /* background: #2872B3; */
             background-image: linear-gradient(to bottom,#39A3FF 0%,#1b4871 100%);
            -webkit-transform: translateY(-2px);
            -ms-transform: translateY(-2px);
            transform: translateY(-2px);
             ${p => p.theme === 'cancel' && (`
                     background: transparent linear-gradient(180deg, #9e9e9e 0%, #383838 100%) ;
                 `)}
            ${p => p.theme === 'close' && (`
             background: #E44F4F 0% 0% no-repeat padding-box;
               `)}
                ${p => p.theme === 'dark' && (`
                    background: transparent ;
                    box-shadow: 0px 0px 2px 3px #ffffff30;
                    border-color:#fff;
               `)}
         }
        :active{
             cursor: pointer;
             background: #2872B3;
             box-shadow: inset 0px 3px 7px #3c4fe0;
            -webkit-transform: translateY(2px);
            -ms-transform: translateY(2px);
            transform: translateY(2px);
             ${p => p.theme === 'cancel' && (`
                     background: transparent linear-gradient(180deg, #9e9e9e 0%, #383838 100%) ;
                 `)}
            ${p => p.theme === 'close' && (`
             background: #E44F4F 0% 0% no-repeat padding-box;
               `)}
         }
        
        ${p => p.theme === 'style2' && (`
            box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
            border: 0.125rem solid #cf212100;
            
            `)}

        ${p => p.theme === 'cancel' && (`
               background: transparent linear-gradient(180deg, #9e9e9e 0%, #4f4f4f 100%)
               `)}
        ${p => p.theme === 'close' && (`
             background: #E44F4F 0% 0% no-repeat padding-box;
               `)}
        ${p => p.theme === 'dark' && (`
            background: transparent;
            background-image: none;
            width:100px;
            box-shadow: 4px 5px 0px #ffffff30;
            border-radius: 5px;
            border: solid #ffffff82;
               `)}
       
    }
    .Mui-disabled{
        color: rgba(255, 255, 255, 0.3);
        box-shadow: none;
        background: rgba(255, 255, 255, 0.12);
    }
}
`;


const StyledButton = React.memo(({ theme = '', className, children, inputProps, ...rest }) => {
    return (
        <Wrapper theme={theme} className={className}>
            <MuButton {...inputProps} {...rest} >{children}</MuButton>
        </Wrapper>
    )
}
)

StyledButton.propTypes = {
    children: PropTypes.any,
    /** button variations '', 'style2' */
    theme: PropTypes.oneOf(['', 'style2', 'cancel', 'error', 'close', 'dark']),
}

export default StyledButton


/**
 * Button Component
 * @augments `Button<theme defaultProps/>`
 * `variants` - `'fill-white', 'fill-blue', 'nofill-white', 'nofill-blue'`
 */
export const Button = React.memo(({ loading, className, variant = 'fill-blue', spinnerProp, children, ...rest }) => {
    const classes = useStyles({ variant });

    return (
        <MuButton
            className={`${classes.root} ${classes[styleObject[variant]]} ${className}`}
            classes={{
                disabled: classes.disabledButton,
                label: classes.label,
            }}
            {...rest}
        >
            {loading ? <Spinner style={{ color: variant === "fill-white" ? "#3590E1" : "#fff" }} {...spinnerProp} disabled={rest?.disabled} /> : children}
        </MuButton>

    )
})
Button.propTypes = {
    children: PropTypes.any,
    loading: PropTypes.any,
    spinnerProp: PropTypes.any,
    variant: PropTypes.oneOf(['fill-white', 'fill-blue', 'nofill-white', 'nofill-blue', 'nofill', 'nofill-link'])
}


const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: "10px auto",
        padding: " 10px 20px",
        minWidth: "140px",
        fontSize: "12px",
        transition: '0.25s all ease',
        borderRadius: "7px",

        "&:active": {
            transform: 'scale(0.97)',
        }
    },

    fillWhite: {
        backgroundColor: "#ffffffcc",
        color: '#000',
        "& $label": {
            fontWeight: '600',
        },
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    fillblue: {
        backgroundColor: "#3590E1",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#3590e1ad",
        }
    },
    nofillWhite: {
        boxShadow: " 0px 0px 2px 2px #ffffff30",
        color: "#ffffffd6",
        border: " solid #ffffffd6 2px",
        "&:hover": {
            boxShadow: "0px 0px 2px 4px #ffffff30",
        }
    },
    nofillBlue: {
        color: "#fff",
        backgroundColor: "#0b0d1a",
        border: 'solid #03a9f4d9',
        "&:hover": {
            boxShadow: "0px 0px 2px 4px #ffffff30",
        }
    },
    nofillLink: {
        color: "#3590E1",
        backgroundColor: "#0000",
        border: 'solid #0000',
        margin: '0',
        padding: '0',
        fontSize: '16px',
        minWidth: '0',
        width: 'fit-content',
        textTransform: 'none',
        "&:hover": {
            color: "#3590e1c7",
            boxShadow: "none",
        }
    },
    nofill: {
        color: "#fff",
        backgroundColor: "#0000",
        border: 'solid #0000',
        "&:hover": {
            boxShadow: "none",
        }
    },
    disabledButton: {
        "&&": {
            opacity: '0.5',
        },
        "&$fillWhite": {
            color: '#000',
        },
        "&$fillblue": {
            color: '#ffffff42',
        },
        "&$nofillWhite": {
            color: '#ffffff42',
        },
        "&$nofillBlue": {
            color: '#ffffff42',
        },
    },
    label: {},
}));

const styleObject = {
    'fill-white': 'fillWhite',
    'fill-blue': 'fillblue',
    'nofill-white': 'nofillWhite',
    'nofill-blue': 'nofillBlue',
    'nofill-link': 'nofillLink',
    'nofill': 'nofill',
}
