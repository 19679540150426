import { gql, useQuery } from "@apollo/client";

const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      iso2Code
      name
      currency
    }
  }
`;

export const useGetCountriesQuery = () => useQuery(GET_COUNTRIES);
