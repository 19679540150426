import React from 'react'
import styled from 'styled-components'

const PageLoader = () => {

    const [state, setstate] = React.useState(false);
    React.useEffect(() => {
        setstate((currentState) => !currentState)
    }, []);

    return (
        <>
            <Wrapper>
                <svg id="my_svg" className={state ? 'active' : ''} width="300" height="305" viewBox="0 0 300 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="150" cy="152.5" rx="150" ry="152.5" data-llp-stroke-color="#ffffff29" data-llp-stroke-width="3" fill="#2995E2" data-llp-duration="7000" className="svg-elem-1"></ellipse>
                    <g filter="">
                        <path data-llp-duration="500" data-llp-stroke-opacity="0.3" d="M81 66L178 4C148 29 83.4 83.4 65 101C65 81.8 75.6667 69.6667 81 66Z" fill="#ffffff45" className="svg-elem-2"></path>
                        <path data-llp-reverse="true" data-llp-duration="1000" data-llp-stroke-opacity="0.3" d="M142 66L65 121C61 140.726 74 137.219 81 133C101.333 119 144 88.4 152 78C152.8 67.6 145.667 65.6667 142 66Z" fill="#ffffff45" className="svg-elem-3"></path>
                        <path data-llp-duration="1500" data-llp-stroke-opacity="0.3" d="M142 111L96 144C93.2 156 102.833 158.667 108 158.5C115.5 152.667 134.8 137.7 152 124.5C160 112.5 148.667 109.833 142 111Z" fill="#ffffff45" className="svg-elem-4"></path>
                        <path data-llp-duration="2000" data-llp-stroke-opacity="0.3" d="M196 135C205.5 118.5 192.667 120.333 186 121C177 127.667 155.6 143.6 142 154C137.6 167.2 146.833 168.167 152 167L196 135Z" fill="#ffffff45" className="svg-elem-5"></path>
                        <path data-llp-duration="2500" data-llp-stroke-opacity="0.3" d="M231 130L152 187C146.4 201.4 157.667 202.333 164 201C180.667 188.667 227 158.5 242.5 144C250.5 128.5 238 127 231 130Z" fill="#ffffff45" className="svg-elem-6"></path>
                        <path data-llp-duration="3000" data-llp-stroke-opacity="0.3" d="M89 291L250 162.5C252 178.5 242.167 192.167 237 197L94 293.5L89 291Z" fill="#ffffff45" className="svg-elem-7"></path>
                        <path data-llp-duration="3500" data-llp-stroke-opacity="0.3" d="M81 66L178 4C148 29 83.4 83.4 65 101C65 81.8 75.6667 69.6667 81 66Z" stroke="#F8F8F8" className="svg-elem-8"></path>
                        <path data-llp-duration="4000" data-llp-stroke-opacity="0.3" d="M142 66L65 121C61 140.726 74 137.219 81 133C101.333 119 144 88.4 152 78C152.8 67.6 145.667 65.6667 142 66Z" stroke="#F8F8F8" className="svg-elem-9"></path>
                        <path data-llp-reverse="true" data-llp-duration="4500" data-llp-stroke-opacity="0.3" d="M142 111L96 144C93.2 156 102.833 158.667 108 158.5C115.5 152.667 134.8 137.7 152 124.5C160 112.5 148.667 109.833 142 111Z" stroke="#F8F8F8" className="svg-elem-10"></path>
                        <path data-llp-duration="5000" data-llp-stroke-opacity="0.3" d="M196 135C205.5 118.5 192.667 120.333 186 121C177 127.667 155.6 143.6 142 154C137.6 167.2 146.833 168.167 152 167L196 135Z" stroke="#F8F8F8" className="svg-elem-11"></path>
                        <path data-llp-reverse="true" data-llp-duration="5500" data-llp-stroke-opacity="0.3" d="M231 130L152 187C146.4 201.4 157.667 202.333 164 201C180.667 188.667 227 158.5 242.5 144C250.5 128.5 238 127 231 130Z" stroke="#F8F8F8" className="svg-elem-12"></path>
                        <path data-llp-duration="6500" data-llp-stroke-opacity="0.3" d="M89 291L250 162.5C252 178.5 242.167 192.167 237 197L94 293.5L89 291Z" stroke="#F8F8F8" className="svg-elem-13"></path>
                    </g>
                    <defs>
                        <filter id="filter0_d" x="59.2513" y="3" width="196.013" height="299.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
                            <feOffset dy="4"></feOffset>
                            <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                        </filter>
                    </defs>
                </svg>

            </Wrapper>

        </>
    )
}

export default PageLoader;


const Wrapper = styled.div`
    display:flex;
    overflow:hidden;
    justify-content: center;
    height: 100vh;
    align-items:center;
    width:100%;
    position: fixed;
    z-index:9;
    background-color:#161525;

    svg{
        width: 10rem;
        height:10rem;
    }


 svg .svg-elem-1 {
  stroke-dashoffset: 952.3642316263222px;
  stroke-dasharray: 952.3642316263222px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(41 149 226 / 40%);
}

svg .svg-elem-2 {
  stroke-dashoffset: 305.9017639160156px;
  stroke-dasharray: 305.9017639160156px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-3 {
  stroke-dashoffset: 234.48805236816406px;
  stroke-dasharray: 234.48805236816406px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-4 {
  stroke-dashoffset: 160.65830993652344px;
  stroke-dasharray: 160.65830993652344px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-4 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-5 {
  stroke-dashoffset: 159.3668212890625px;
  stroke-dasharray: 159.3668212890625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg-elem-5 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-6 {
  stroke-dashoffset: 248.49664306640625px;
  stroke-dasharray: 248.49664306640625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg-elem-6 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-7 {
  stroke-dashoffset: 424.2236022949219px;
  stroke-dasharray: 424.2236022949219px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg-elem-7 {
  stroke-dashoffset: 0;
  fill: rgba(255, 255, 255, 0.27);
}

svg .svg-elem-8 {
  stroke-dashoffset: 305.9017639160156px;
  stroke-dasharray: 305.9017639160156px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg-elem-8 {
  stroke-dashoffset: 0;
}

svg .svg-elem-9 {
  stroke-dashoffset: 234.48805236816406px;
  stroke-dasharray: 234.48805236816406px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg-elem-9 {
  stroke-dashoffset: 0;
}

svg .svg-elem-10 {
  stroke-dashoffset: 160.65830993652344px;
  stroke-dasharray: 160.65830993652344px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg-elem-10 {
  stroke-dashoffset: 0;
}

svg .svg-elem-11 {
  stroke-dashoffset: 159.3668212890625px;
  stroke-dasharray: 159.3668212890625px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg-elem-11 {
  stroke-dashoffset: 0;
}

svg .svg-elem-12 {
  stroke-dashoffset: 248.49664306640625px;
  stroke-dasharray: 248.49664306640625px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg-elem-12 {
  stroke-dashoffset: 0;
}

svg .svg-elem-13 {
  stroke-dashoffset: 424.2236022949219px;
  stroke-dasharray: 424.2236022949219px;
  -webkit-transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
                        fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
          transition: stroke-dashoffset 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
                fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-13 {
  stroke-dashoffset: 0;
}

`;
