import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
// import { useLogout } from '../../mutations/logoutMutation';
import { useGetJwt } from './getJwt';
import { useGetSubscriptionQuery } from './getSubscription';


const GET_PROFILE = gql`
  query getProfile($id:Int!){
    profile(id: $id){
      id
      email
      firstname
      lastname
    }
  }
  `;
const GET_USER_ID = gql`
  query getId{
    userId @client
  }
  `;
const IS_SUBSCRIBED = gql`
  query isSubscribed{
    isSubscribed @client
  }
  `;



export const useGetUserIdQuery = () => useQuery(GET_USER_ID);
export const useGetisSubscribedQuery = () => useQuery(IS_SUBSCRIBED);


export const useGetProfileQuery = () => {
  // const logout = useLogout();
  const history = useHistory();
  const { data: { userId } = {}, loading, error } = useQuery(GET_USER_ID);

  const skip = !userId;
  if (!loading && !userId) {
    // logout();
  }
  if (error) {
    history.push('/403')
  }


  const profileData = useQuery(GET_PROFILE,
    {
      skip,
      variables: { id: userId },
      notifyOnNetworkStatusChange: true,
      // fetchPolicy: "network-only",
    }
  );
  // if (!loading && profileData?.data && !profileData?.data?.profile) {
  //   // logout();
  // }

  return profileData;
}


export const useGenerateFrontendUserID = () => {
  const { data: { subscription } = {}, loading: subLoading, error: subError } = useGetSubscriptionQuery()
  const { data: { jwt: { usertype } = {} } = {} } = useGetJwt()
  const { data: { userId } = {}, loading } = useQuery(GET_USER_ID);

  if (!subLoading && !subError) {
    const utype = usertype.substr(0, 1);
    const ptype = subscription?.pricingPlan?.title?.split(" ")[1]?.substr(0, 2);
    const d = new Date();
    const dmonth = (d.getMonth() + 1).toString().length < 2 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const dyear = d.getFullYear().toString().substr(-2)
    const date = `${(dmonth)}${dyear}`
    return `${utype}${ptype}${date}${userId}`.toUpperCase()
  }
  return userId;
}