// UI UserTypes
export const INDIVIDUAL = "individual";
export const ENTERPRISE = "enterprise";
export const TRAINER = "trainer";
export const CAREER_CONSULTANT = "career-consultant";
export const INDIVIDUAL_ADDON = "individual_addon";

// Types required for accessing dashboard
export const USERTYPES = { INDIVIDUAL, ENTERPRISE, TRAINER, CAREER_CONSULTANT };

//Access types based on pricing plan
export const DASHBOARD_ACCESS_TYPES = {
  STACK_GENIE: "STACK_GENIE",
  STACK_SPECIALIST: "STACK_SPECIALIST",
  STACK_GURU: "STACK_GURU",
};

export const ACCESS_TYPES = {
  ...DASHBOARD_ACCESS_TYPES,
  NO_ACCESS: "NO_ACCESS",
};

//Usertypes that can access dashboard without a subscription
export const ACCESS_WITHOUT_SUBSCRIPTION = [CAREER_CONSULTANT, TRAINER];

//UI DynamicType
export const LIGHT = "light";
export const DARK = "dark";

//UI Learning renderTypes
export const renderTypes = {
  VIDEO: "video",
  EXERCISE: "exercise",
  QUIZ: "quiz",
  PROBLEM_STATMENT: "problem_statement",
  SUBMISSION: "submission",
  SOLUTION: "solution",
};

//productTypes
export const productTypes = {
  COURSE: "course",
  CAREERPATH: "careerpath",
  FREECOURSES: "free-courses",
  MENTORING: "mentoring",
};

export const getAccess = (type) =>
  type === productTypes.COURSE
    ? ACCESS_TYPES.STACK_GENIE
    : productTypes.CAREERPATH || productTypes.MENTORING
    ? ACCESS_TYPES.STACK_SPECIALIST
    : ACCESS_TYPES.STACK_GURU;

export const SubscriptionPlans = {
  GENIE: "Genie",
  SPECIALIST: "Specialist",
  GURU: "Guru",
};

//alertTypes
export const alertTypes = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

//subscriptionStatus
export const SubscriptionStatus = {
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  CANCELLED: "CANCELLED",
  PENDING: "PENDING",
  TRIAL: "TRIAL",
};

const { ACTIVE, CANCELLED, TRIAL } = SubscriptionStatus;

// Full Access status
export const FullAccessSubscriptionStatus = {
  ACTIVE,
  CANCELLED,
  TRIAL,
};

// all status should display a message on the dashboard, for notifiy the user of what the status means
