import * as Sentry from "@sentry/react";
import { alertTypes } from "../Constants/uiConstants";
import { alertMessageVar } from "../graphql/config/cache";
import ReactGA from 'react-ga';



const ErrorDispacher = (error = "Something Went Wrong", message = '', componentName = '', response = null, fatal = false) => {
    alertMessageVar({ message: error, severity: alertTypes.ERROR });

    Sentry.captureMessage(JSON.stringify({
        error,
        message,
        componentName,
        response,
        date: Date.now()
    }))

    ReactGA.exception({
        description: JSON.stringify({
            error,
            message,
            componentName,
            response
        }),
        fatal: fatal
    });
}


export default ErrorDispacher;