export const ChargeOptionsFragment = `
 fragment ChargeOptionsFragment on ChargeOption {
    id
    price
    currency
    bundleOnly
    recurring
    optiontype
    upsell
    upsellDiscount
    upsellMessage
    installments{
        id
        frequency
        amount
    }
 }
`;

export const PricingPlanFragment = `
    fragment PricingPlanFragment on Pricingplan {
        id
        title
        plantype
        desc
        link
        trialDurationInDays
        tag
        uiJson
        accessType
        chargeoptions{
           ...ChargeOptionsFragment
        }
        addons{
            id
            title
            plantype
            desc
            trialDurationInDays
            chargeoptions {
                ...ChargeOptionsFragment
            }
        }
    }
    ${ChargeOptionsFragment}
`;

export default PricingPlanFragment;
