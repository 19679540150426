import { gql, useQuery } from "@apollo/client";
import { createRoute } from "../../../globalHelpers/routeHelpers";

const GET_TECH_STACKS = gql`
  query getAllTechCourses {
    stacks {
      id
      title
    }
  }
`;

export const useGetTechCoursesQuery = () => {
  const all = useQuery(GET_TECH_STACKS);
  const { data, loading, error } = all;

  if (!loading && !error) {
    const newArr = [];
    const arr = data.stacks;

    for (let i = 0; i < arr?.length; i++) {
      const title = arr[i].title;
      const id = arr[i].id;

      //create route from title
      const route = createRoute(title, `/techstack/${id}/`);
      // const route = `/techstack/${arr[i].id}`

      // copy and add route to new array
      newArr.push({ ...arr[i], route });
    }

    const dataWithRoutes = { stacks: newArr };
    // return { data, dataWithRoutes, loading, error };
    return { ...all, dataWithRoutes };
  }

  //return the data from `sever` and `dataWithRoutes` as undefined
  return { ...all, dataWithRoutes: undefined };
};
export const GetTechCoursesQuery = () => {
  const all = useQuery(GET_TECH_STACKS);
  const { data, loading, error } = all;

  if (!loading && !error) {
    const newArr = [];
    const arr = data.stacks;

    for (let i = 0; i < arr.length; i++) {
      const title = arr[i].title;
      const id = arr[i].id;

      //create route from title
      const route = createRoute(title, `/techstack/${id}/`);
      // const route = `/techstack/${arr[i].id}`

      // copy and add route to new array
      newArr.push({ ...arr[i], route });
    }

    const dataWithRoutes = { stacks: newArr };
    // return { data, dataWithRoutes, loading, error };
    return { ...all, dataWithRoutes };
  }

  //return the data from `sever` and `dataWithRoutes` as undefined
  return { ...all, dataWithRoutes: undefined };
};
