import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

//style
import "./Layout.css";

//components
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Page } from "../../containers/pageStyles";
import CartMessage from "../cartMessage/CartMessage";
import CookiePolicy from "../cookiePolicy/CookiePolicy";
import AdBanner from "../AdBanner/AdBanner";
import FooterSecondary from "../footerSecondary/FooterSecondary";

//routes
import AlertMessage from "../alertMessage/AlertMessage";
import BaseRoutes from "../../routes/BaseRoutes";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import { useCookies } from "react-cookie";

const botRestrictions = ["/learn", "login", "signup"];

const Layout = (props) => {
  let { pathname: path } = useLocation();
  const [cookies, setCookie] = useCookies(["_cookies"]);

  //tidio chat
  React.useEffect(() => {
    const srcUrl = `//code.tidio.co/w2dflkzqgaeos48vaqlzwoqmymrmhlth.js`;
    const s = document.createElement("script");
    const ele = document.getElementById("tidio-chat");

    const addScript = (src) => {
      s.setAttribute("src", src);
      s.setAttribute("async", "async");
      s.setAttribute("defer", "defer");
      document.body.appendChild(s);
    };

    const removeScript = () => {
      s.remove();
    };

    const timer = setTimeout(() => {
      cookies["_cookies"] !== undefined && addScript(srcUrl);
    }, 10000);

    if (ele) {
      ele.style.display = "block";
    }

    const unmount = botRestrictions.find((e) => path?.includes(e) === true);
    if (unmount) {
      removeScript();
      if (ele) {
        ele.style.display = "none";
      }
    }

    return () => {
      removeScript();
      clearTimeout(timer);
    };
  }, [path, cookies]);

  if (path?.includes("/account") || path?.includes("/login") || path?.includes("/signup")) {
    return (
      <ErrorBoundary>
        <BaseRoutes />
        <AlertMessage />
      </ErrorBoundary>
    );
  } else if (path?.includes("/learn")) {
    return (
      <ErrorBoundary>
        <BaseRoutes />
        <AlertMessage />
      </ErrorBoundary>
    );
  } else if (path.includes("/pdf") || path.includes("/interview-resource/")) {
    return (
      <>
        <Navbar />
        <ErrorBoundary>
          <RouterWrapper2>
            <BaseRoutes />
          </RouterWrapper2>
          <FooterSecondary />
        </ErrorBoundary>
      </>
    );
  }

  // cartMessage, cookiePolicy and AdBannner not need in other paths
  return (
    <>
      <Navbar />
      <ErrorBoundary>
        <BaseRoutes />
        <CartMessage />
        <Footer />
        <CookiePolicy />
        <AdBanner />
        <AlertMessage />
      </ErrorBoundary>
    </>
  );
};

export default Layout;

const RouterWrapper2 = styled.div`
  min-height: calc(100vh - 186.27px);
  height: calc(100% - 122.27px);
  overflow: hidden;
  position: relativee;
`;
