import React from 'react';
import PropTypes from "prop-types";
import styles from './spinner.module.css';

import { CircularProgress } from "@material-ui/core";
import { Loader, LoaderContainer } from '../loadingStyles';
import { ENTERPRISE, INDIVIDUAL } from '../../../Constants/uiConstants';

export default function Spinner({ style: { size = '20px', color = '' } = {}, children, loading, type, className, disabled }) {
    Spinner.module = Spinner_module;
    const calSize = size === "md" ? "30px" : size === "s" ? "15px" : size === "l" ? '50px' : size;
    const _color = type === INDIVIDUAL ? '#2995e2' : type === ENTERPRISE ? '#5f3dc4' : color;
    // console.log(_color);
    return (
        children ? (
            loading ? (
                <div style={{ position: "relative" }} className={className}>
                    <Spinner.module size={calSize} color={_color} type={type} />
                    {children}
                </div>
            ) : (
                <>
                    {children}
                </>
            )
        ) : (
            <>
                <CircularProgress className={`${styles.spinnerIcon} ${className}`} thickness={5} size={calSize} style={{ color: _color, opacity: disabled ? '0.5' : '1' }} />
            </>
        )
    )
}
Spinner.propTypes = {
    children: PropTypes.any,
    loading: PropTypes.bool,
    style: PropTypes.shape({
        size: PropTypes.string,
        size: PropTypes.oneOf(['s', 'md', 'l']),
        color: PropTypes.string,
    })
}


const Spinner_module = ({ size, color }) => {
    return (
        <div className={styles.spinner}>
            <CircularProgress className={styles.spinnerIcon} thickness={5} size={size} style={{ color: color }} />
        </div>
    )
}