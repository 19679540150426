import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './CookiePolicy.css'
import { useCookies } from 'react-cookie';



//components
import CloseIcon from '@material-ui/icons/Close';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px -7px 35px 0px #359be317',
    padding: '20px 32px',
    // height: '100px',

  },
  container: {
    marginLeft: theme.spacing(0),

    "& p ": {
      color: "#afadad",
      fontSize: '0.8rem',
      marginBottom: '0',
    },
  },
  sectionWrapper: {
    // display: 'grid',
    // gridTemplateColumns: '90% 10%',
  },
  header: {
    color: "#fff",
    fontFamily: "OpenSansRegular",
    fontSize: '1.3rem'
  },
  action1: {
    marginRight: theme.spacing(2),
    background: '#2995e2',
    "&:hover": {
      background: '#2995e2b5',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },

  },
  action2: {
    marginRight: theme.spacing(2),
    borderColor: '#2995e2',
    padding: '20px 30px',
    color: '#2995e2',
    "&:hover": {
      color: '#2995e2b5',
      borderColor: '#2995e2b5',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },

  },
  icon: {
    position: 'absolute',
    right: '20px',
    color: '#fff',
    margin: '0 0 0 auto',
    display: 'flex',
  }
}));

export default function Cookies(props) {
  const classes = useStyles();
  const domRef = React.useRef();
  const [isVisible, setVisible] = React.useState(false);
  const [cookies, setCookie] = useCookies(['_cookies']);


  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));

    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }
    return () => domRef.current ? observer?.unobserve(domRef.current) : '';
  }, []);


  const handleClick = (accept) => {
    domRef.current.classList.remove('fade-in-section');
    domRef.current.classList.add('fade-out-section');
    setCookie('_cookies', accept, {
      path: '/',
    });
  }

  const content = {
    'header': 'Cookie Policy',
    'description': 'We use cookies to personalise content, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners.',
    'primary-action': 'Agree',
    'secondary-action': 'Refuse cookies',
    ...props.content
  };

  return (
    cookies['_cookies'] === undefined && (
      <Box position="fixed" width="100%" bottom={0} p={4} zIndex="modal" color="textSecondary" bgcolor="#000215" className={`${classes.root}  ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
        <Container maxWidth="md" className={classes.container}>
          <IconButton onClick={() => handleClick(true)} id='close-ad' className={classes.icon}          >
            <CloseIcon />
          </IconButton>
          <div className={`${classes.sectionWrapper}`}>
            <div>

              <Typography variant="h5" component="h2" gutterBottom={true} className={classes.header}>{content['header']}</Typography>
              <Typography variant="subtitle1" paragraph={true}>{content['description']}</Typography>
            </div>
            <div>
              {/* <Button variant="contained" color="primary" className={classes.action1} onClick={() => handleClick(true)}>{content['primary-action']}</Button> */}
              {/* <Button variant="outlined" color="primary" className={classes.action2} onClick={() => handleClick(false)}>{content['secondary-action']}</Button> */}
            </div>
          </div>
        </Container>
      </Box>
    )
  );
}