import { useCookies } from "react-cookie";
import * as Cookies from "js-cookie";
import { jwtVar } from "./cache";
import { client } from "./apolloClient";

//identity names
export const JWT = '_jt_ac'


export const useAuthToken = () => {
    const [cookies, setCookie, removeCookie] = useCookies([JWT]);
    const getAuthToken = () => cookies[JWT]?.token;
    return [, getAuthToken,];
}


export const useUserId = () => {
    const [cookies, setCookie, removeCookie] = useCookies([JWT]);
    const getUserId = () => cookies[JWT]?.userId;
    return [, getUserId,];
}

export const useJwt = () => {
    const [cookies, setCookie, removeCookie] = useCookies([JWT]);

    const setJwt = (jwt) => setCookie(JWT, JSON.stringify(jwt), {
        path: '/',
        // httpOnly: true,
        // secure: true,
        sameSite: true,
    });
    const updateJwt = async (updates) => {
        const jwt = getJwt();
        const obj = { ...jwt, ...updates };
        setJwt(obj);
        jwtVar(obj);
    };

    const getJwt = () => {
        return cookies[JWT];
    }
    const removeJwt = () => removeCookie(JWT, {
        path: '/',
        sameSite: true,
    });
    return [setJwt, getJwt, removeJwt, updateJwt];
}

//without Hook
export const handleJwt = () => {

    const setJwt = async (jwt) => await Cookies.set(JWT, JSON.stringify(jwt), {
        path: '/',
        sameSite: true,
    });
    const updateJwt = async (updates) => {
        const jwt = getJwt();
        const obj = { ...JSON.parse(jwt), ...updates };
        setJwt(obj)
        jwtVar(obj);
    };

    const getJwt = () => {
        return Cookies.get(JWT);
    }
    const removeJwt = async () => Cookies.remove(JWT, {
        path: '/',
        sameSite: true,
    });
    return [setJwt, getJwt, removeJwt, updateJwt];
}