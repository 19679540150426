import courseImage from "../../assets/images/course1.jpg";

export const videoContents = {
  title: "Module 1 Sportsman do offending",
  modules: [
    {
      id: 1,
      moduleTitle: "Module 1",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "30:00",
          completed: true,
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "0",
          completed: true,
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "02:20",
          completed: true,
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "02:20",
          completed: false,
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she jnjkdsf dnsfds jdskf",
          time: "0",
          completed: true,
        },
      ],
    },
    {
      id: 2,
      moduleTitle: "Module 2",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
        },
      ],
    },
    {
      id: 3,
      moduleTitle: "Module 3",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
        },
      ],
    },
    {
      id: 4,
      moduleTitle: "Module 5",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
        },
      ],
    },
    {
      id: 5,
      moduleTitle: "Module 5",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
        },
      ],
    },
    {
      id: 6,
      moduleTitle: "Module 6",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
          completed: true,
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
          completed: true,
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
          completed: true,
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
          completed: false,
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
          completed: false,
        },
      ],
    },
    {
      id: 7,
      moduleTitle: "Module 7",
      list: [
        {
          id: 1,
          type: "video",
          subTitle: "Sportsman do offending",
          time: "3 min",
        },
        {
          id: 2,
          type: "exercise",
          subTitle: "Supported extremity",
          time: "15 min",
        },
        {
          id: 3,
          type: "video",
          subTitle: "Breakfast by listening",
          time: "15 min",
        },
        {
          id: 4,
          type: "video",
          subTitle: "Decisively advantages nor",
          time: "9 min",
        },
        {
          id: 5,
          type: "quiz",
          subTitle: "Expression unpleasing she",
          time: "15 min",
        },
      ],
    },
  ],
};

export const companiesLogo = [
  require("../../assets/images/landing_images/H4.1.png"),
  require("../../assets/images/landing_images/H4.3.png"),
  require("../../assets/images/landing_images/H4.4.png"),
  require("../../assets/images/landing_images/H4.5.png"),
  require("../../assets/images/landing_images/H4.6.png"),
  require("../../assets/images/landing_images/H4.7.png"),
  require("../../assets/images/landing_images/H4.8.png"),
];

export const careerPath1 = {
  data: [
    {
      title: "Data Analyst",
      img: require("../../assets/images/landing_images/H3.1.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Data Scientist",
      img: require("../../assets/images/landing_images/H3.2.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Blockchain Developer",
      img: require("../../assets/images/landing_images/H3.3.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Expert Data Scientist",
      img: require("../../assets/images/landing_images/H3.4.png"),
    },
    {
      title: "AI Engineer",
      img: require("../../assets/images/landing_images/H3.5.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Advance Bloackchain",
      img: require("../../assets/images/landing_images/H3.6.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "DevOps Practitioner",
      img: require("../../assets/images/landing_images/H3.6.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Cloud BI Developer",
      img: require("../../assets/images/landing_images/H3.1.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
    {
      title: "Big Data Enineer",
      img: require("../../assets/images/landing_images/H3.2.png"),
      desc: "Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer of though narrow marked at.",
    },
  ],
};

export const studentFeedback = {
  title: "Hear What Our Students Say",
  data: [
    {
      user: {
        name: "Dominic  ",
        title: "Data Wrangler ",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "Starting and completing my programme with StackwisR has inevitably changed my life for the better. My job opportunities after completion are great. A massive thank to StackwisR. ",
    },
    {
      user: {
        name: "Danny  ",
        title: "Data Scientist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "The Data Science program is a truly successful and high-impact one. Switching my career to Analytics within a very short time is truly remarkable. I highly recommend to anyone who wishes to take a shortcut into a data science career. ",
    },
    {
      user: {
        name: "Andre   ",
        title: "Data Scientist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "In my Data Science programme, I had opportunities to work on various interesting business problems or use cases from telecommunication, banking, retail and many more sectors. This learning experience was truly valuable and enriching to my career.",
    },
    {
      user: {
        name: "Sam  ",
        title: "Senior Data Analyst",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "Going for StackwisR training was the best thing that happened to me. I knew about data analytics and had a Computer Science background, but the StackwisR program guided me on how to maximize my potential. With the courses and real-life projects, my transition to a Data Analyst role was easy. ",
    },
    {
      user: {
        name: "Victor ",
        title: "Business Intelligence Analyst         ",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "The training was conducted with excellent theories and practical projects. I secured a good job and have now moved from one role to another with ease.",
    },
    {
      user: {
        name: "Jack ",
        title: "Business Intelligence Specialist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "After university, I got a graduate job and it wasn’t rewarding compared to the career path in Analytics. After doing some research, I signed up for the StackwisR courses, finished them, and got myself a new job in IT.  From my perspective it was perfect. ",
    },
    {
      user: {
        name: "Joel  ",
        title: "Data Science Consultant",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: " ",
      },
      rating: 5,
      message:
        "StackwisR helped me shape my career. I was well trained. The CV templates and interview tips from the site are very helpful. Thanks to StackwsiR, I got a great job.",
    },
    {
      user: {
        name: "Mike   ",
        title: "Data Analyst ",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "",
      },
      rating: 5,
      message:
        "The training was excellent, though challenging. The project was very helpful as I had to solve real-life problems using the learnt skills. After 5 interviews, I got an analytics job. Overall, I am very pleased!",
    },
    {
      user: {
        name: "Rochel  ",
        title: "Business Intelligence Analyst",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: " ",
      },
      rating: 5,
      message:
        "I had no idea about Big Data before enrolling with StackwisR. I have learned so much from the courses and I managed to get a good job with great prospects. Big thanks to the StackwisR platform. ",
    },
  ],
};

export const studentFdDummy = {
  title: "What Our Students Say about Us",
  data: [
    {
      user: {
        firstname: "Jane",
        title: "Data Scientist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "sfsdfsd Raytheon",
      },
      rating: 5,
      message:
        "The curriculum was directly targeted toward applied techniques with high profile projects – real datasets with industry partners. It helped me achieve my career transition goal.",
    },
    {
      user: {
        firstname: "Andrew",
        title: "Data Scientist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "sdfsdfsdfsdfds Raytheon",
      },
      rating: 5,
      message:
        "The curriculum was directly targeted toward applied techniques with high profile projects – real datasets with industry partners. It helped me achieve my career transition goal.",
    },
    {
      user: {
        firstname: "Femi",
        title: "Data Scientist",
        img: require("../../assets/images/iStock-1.png"),
      },
      course: {
        title: "Raytheon",
      },
      rating: 5,
      message:
        "The curriculum was directly targeted toward applied techniques with high profile projects – real datasets with industry partners. It helped me achieve my career transition goal.",
    },
  ],
};

export const footerData = {
  SERVICES: [
    {
      title: "Stack Courses",
      link: "/techstack/",
    },
    {
      title: "Career Paths",
      link: "/#careerpaths",
    },
    {
      title: "Practical Project",
      link: "/project-library",
    },
    {
      title: "Live Classes",
      link: "/events",
    },
    {
      title: "Enterprise Training",
      link: "/enterprise",
    },
    {
      title: "View Plans",
      link: "/pricing-plan",
    },
  ],
  PRODUCTS: [
    {
      title: "Stack Genie",
      link: "/individual-membership-intro#stack-genie",
    },
    {
      title: "Domain Stack Specialist",
      link: "/individual-membership-intro#domain-stack-specialist",
    },
    {
      title: "Stack Guru",
      link: "/individual-membership-intro#stack-guru",
    },
    {
      title: "Talent Incubator",
      link: "/enterprise-membership-intro#talent-incubator",
    },
    {
      title: "Performance Booster",
      link: "/enterprise-membership-intro#performance-booster",
    },
  ],
  COMPANY: [
    {
      title: "About Us",
      link: "/about",
    },
    {
      title: "Customer Stories",
      link: "/customers-stories",
    },
    {
      title: "Careers",
      link: "/career",
    },
  ],
  RESOURCES: [
    {
      title: "CV Template",
      link: "/career-service",
    },
    {
      title: "Interview Video",
      link: "/career-service",
    },
    {
      title: "Leadership Courses",
      link: "/career-service",
    },
    {
      title: "Subscription",
      link: "/subscribe",
    },
  ],
  SUPPORT: [
    {
      title: "Contact Us",
      link: "/contact-us",
    },
    {
      title: "FAQ",
      link: "/faq",
    },
  ],
};

export const socialData = [
  {
    img: require("../../assets/images/fb.png"),
    link: "https://www.facebook.com/StackwisR-339622283917792/?_rdr",
  },
  {
    img: require("../../assets/images/insta.png"),
    link: "https://www.instagram.com/stackwisr/",
  },
  // {
  //   img: require("../../assets/images/twitter.png"),
  //   link: "#",
  // },
  {
    img: require("../../assets/images/linkedin.png"),
    link: "https://www.linkedin.com/company/stackwisr-limited/",
  },
  {
    img: require("../../assets/images/youtube.png"),
    link: "https://www.youtube.com/channel/UCrNV7xSpcMK0HrWEGhrtxdA",
  },
];

export const otherPages = [
  {
    title: "Sign Up",
    link: "/signup",
  },
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
  {
    title: "Enterprise Membership Intro",
    link: "/enterprise-membership-intro",
  },
  {
    title: "Individual Membership Intro",
    link: "/individual-membership-intro",
  },
  {
    title: "Subscribe",
    link: "/subscribe",
  },
  {
    title: "community",
    link: "/community",
  },
  {
    title: "quiz",
    link: "/quiz",
  },
  {
    title: "Terms and Conditions",
    link: "/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "Career Exam",
    link: "/careerExam",
  },
  {
    title: "Project Library",
    link: "/project-library",
  },
  {
    title: "Project Introduction",
    link: "/project-introduction",
  },
  {
    title: "Career Service",
    link: "/career-service",
  },
  {
    title: "Stackwisr Career Opportunity",
    link: "/career",
  },
  {
    title: "Faq",
    link: "/faq",
  },
  {
    title: "Business",
    link: "/business",
  },
  {
    title: "Checkout",
    link: "/checkout",
  },
  {
    title: "Techstack",
    link: "/techstack",
  },
];

export const accountList = [
  {
    title: "Dashboard",
    link: "/account",
  },
  {
    title: "Profile",
    link: "/account/profile",
  },
];

export const supportList = [
  {
    title: "Tailored CV template",
    img: require("../../assets/images/careerpath/C3.1.png"),
    id: "cv-template",
  },
  {
    title: "Interview Q&A",
    img: require("../../assets/images/careerpath/C3.2.png"),
    id: "interview-resource",
  },
  {
    title: "Job Research",
    img: require("../../assets/images/careerpath/C3.3.png"),
    id: "job-search",
  },
  {
    title: "Job Market Update",
    img: require("../../assets/images/careerpath/C3.4.png"),
    id: "job-market",
  },
];
export const careerpathJourney = [
  {
    title: "Start Your Courses",
    desc: "You would be able to access your dashboard and start to learn the courses of your choice at one click",
    img: require("../../assets/images/careerpath/C2.1.png"),
  },
  {
    title: "Career Path Certification",
    desc: "Successful completion certificate will be generated by StackwisR when you finish learning a career path",
    img: require("../../assets/images/careerpath/C2.2.png"),
  },
  {
    title: "Practical Projects Certification",
    desc: "Apply your learning and test your knowledge with real-world projects from our project library",
    img: require("../../assets/images/careerpath/C2.3.png"),
  },
  {
    title: "Career Support",
    desc: "Get your dream job by accessing to our comprehensive career support. We gather and create all the materials to help you to get the best result",
    img: require("../../assets/images/careerpath/C2.4.png"),
  },
];

export const coursesLearningJrny = [
  {
    title: "Plan Your Learning",
    desc: "You can easily schedule the time you want to learn on the learning calendar, which will be an excellent reminder to help you keep on track of your progress",
    img: require("../../assets/images/course/SC1.1.png"),
  },
  {
    title: "Start Your Course",
    desc: "You would be able to access your dashboard and start to learn the courses of your choice at one click",
    img: require("../../assets/images/course/SC1.2.png"),
  },
  {
    title: "Module Quiz",
    desc: "Test your skill after each module and get instant feedback as soon as you submit the answer. You will always know if you are on the right track",
    img: require("../../assets/images/course/SC1.3.png"),
  },
  {
    title: "Completion Certification",
    desc: "Successful completion certificate will be generated by StackwisR when you finish a course",
    img: require("../../assets/images/course/SC1.4.png"),
  },
];

export const breadCrumbCareerPathEx = {
  title: "Career Path Exam",
  subTitle: "Software Developer",
  dec: "Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
};

export const breadCrumbCourseQuiz = {
  title: "Course Quiz - Module 1",
  dec: "Microsoft Excel Intermediate to Advanced(Course Name)",
};

export const breadCrumbTechCourse = {
  subtitle: "Tech Training Courses",
  title: "Stay ahead in your digital career",
  background: "",
  dec: "StackwisR is the modern training tool for workers with a continual-learning mindset. It keeps tech job skills and knowledge up to date, staying current with the latest digital trends. StackwisR grows your 'tech stack' AI & Big Data skills.",
};

export const breadCrumbPricingPlan = {
  title: "PRICING AND PLANS",
  subtitle: "Learn the most in-demand tools that are driving the market to stay ahead of future work",
  dec: "Choose a suitable plan below to start your free trial",
};

export const examJourney = ["Career Path Exam", "Pass Exam", "StackwisR Career Path Certificate"];

export const BottomCrumbExam = {
  title: "Start Your Exam Now",
  dec: "Sportsman do offending supported extremity breakfast by listening.Decisively advantages nor",
  data: ["60 Questions", "60 Questions", "60 Questions"],
};

export const careerPathExamQust = [
  {
    title:
      "2. Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
    id: "questionTwo",
    type: "checkbox",
    // answer: {
    //   id: ['A', 'B'],
    //   explanation: 'nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed'
    // },
    options: [
      {
        value: "A. Sportsman do offending supported",
        label: "A",
        answer: true,
      },
      {
        value: "B. Extremity breakfast by listening",
        label: "B",
        answer: true,
      },
      {
        value: "C. Decisively advantages nor",
        label: "C",
        answer: false,
      },
      {
        value: "D. Expression unpleasing she led met",
        label: "D",
        answer: false,
      },
    ],
  },
  {
    title:
      "1. Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
    id: "questionOne",
    type: "radio",
    // answer: {
    //   id: ['A'],
    //   explanation: 'do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.'
    // },
    options: [
      {
        value: "A. Sportsman do offending supported",
        label: "A",
        answer: true,
      },
      {
        value: "B. Extremity breakfast by listening",
        label: "B",
        answer: false,
      },
      {
        value: "C. Decisively advantages nor",
        label: "C",
        answer: false,
      },
      {
        value: "D. Expression unpleasing she led met",
        label: "D",
        answer: false,
      },
    ],
  },

  {
    title:
      "3. Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
    id: "questionThree",
    type: "radio",
    // answer: {
    //   id: ['A'],
    //   explanation: 'nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed'
    // },
    options: [
      {
        value: "A. Sportsman do offending supported",
        label: "A",
        answer: true,
      },
      {
        value: "B. Extremity breakfast by listening",
        label: "B",
      },
      {
        value: "C. Decisively advantages nor",
        label: "C",
      },
      {
        value: "D. Expression unpleasing she led met",
        label: "D. Sportsman do offending supported",
      },
    ],
  },
  {
    title:
      "4. Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
    id: "questionFour",
    type: "checkbox",
    // answer: {
    //   id: ['A'],
    //   explanation: 'nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed'
    // },
    options: [
      {
        value: "A. Sportsman do offending supported",
        label: "A",
      },
      {
        value: "B. Extremity breakfast by listening",
        label: "B",
      },
      {
        value: "C. Decisively advantages nor",
        label: "C",
      },
      {
        value: "D. Expression unpleasing she led met",
        label: "D",
      },
    ],
  },
  {
    title:
      "5. Sportsman do offending supported extremity breakfast by listening. Decisively advantages nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed.",
    id: "questionFive",
    type: "checkbox",
    // answer: {
    //   id: ['A'],
    //   explanation: 'nor expression unpleasing she led expression unpleasing she led met. Estate was tended ten boy nearer seemed'
    // },
    options: [
      {
        value: "A. Sportsman do offending supported",
        label: "A",
      },
      {
        value: "B. Extremity breakfast by listening",
        label: "B",
      },
      {
        value: "C. Decisively advantages nor",
        label: "C",
      },
      {
        value: "D. Expression unpleasing she led met",
        label: "D",
      },
    ],
  },
];

export const studentStatus = [
  {
    courseName: "Microsoft Excel Intermediate to Advanced",
    progress: "40",
    totalTime: "6h",
    status: "In Progress",
  },
  {
    courseName: "Microsoft Excel Intermediate to Advanced",
    progress: "55",
    totalTime: "8h 21m",
    status: "In Progress",
  },
  {
    courseName: "Microsoft Excel Intermediate to Advanced",
    progress: "100",
    totalTime: "16h 30m",
    status: "Complete",
  },
];

export const studentProjects = [
  {
    projectName: "Project Initiation",
    status: "complete",
  },
  {
    projectName: "Framing-scope boundaring",
    status: "complete",
  },
  {
    projectName: "code build, data prepartion",
    status: "Not Complete",
  },
  {
    projectName: "deployment/solution",
    status: "Not Complete",
  },
  {
    projectName: "validation",
    status: "Not Complete",
  },
  {
    projectName: "Review the complete solutions",
    status: "Not Complete",
  },
];

export const enrolledList = [
  {
    name: "John Lewis",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "25",
    status: "In Progress",
    badges: "3",
  },
  {
    name: "Maggie K",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "40",
    status: "In Progress",
    badges: "3",
  },
  {
    name: "Kenya B",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "55",
    status: "In Progress",
    badges: "4",
  },
  {
    name: "Eason G",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "100",
    status: "Complete",
    badges: "15",
  },
  {
    name: "Maggie K",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "40",
    status: "In Progress",
    badges: "7",
  },
  {
    name: "Kenya B",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "55",
    status: "In Progress",
    badges: "8",
  },
  {
    name: "Eason G",
    team: "Team 1",
    startDate: "DD/MM/YYYY",
    lastDate: "DD/MM/YYYY",
    lastActivity: "DD/MM",
    completion: "100",
    status: "Complete",
    badges: "15",
  },
];

export const interviewVideos = [
  {
    title: "Top 5 Interview questions",
    thumbnail: courseImage,
    buttonLink: "#",
    dec: "Description of the project",
  },
  {
    title: "Top 5 Java questions",
    thumbnail: courseImage,
    buttonLink: "#",
    dec: "Description of the project",
  },
  {
    title: "Top 5 github tips",
    thumbnail: courseImage,
    buttonLink: "#",
    dec: "Description of the project",
  },
  {
    title: "Hello world",
    thumbnail: courseImage,
    buttonLink: "#",
    dec: "Description of the projectDescription of the projectDescription of the projectDescription of the project",
  },
];
export const onlyAtStack = {
  default: [
    {
      id: "1",
      img: require("../../assets/images/landing_images/H2.1_.png"),
      title: "Career Driven Programmes",
      desc: "Develop lifelong skillsets within the fastest growing careers in the digital economy",
    },
    {
      id: "2",
      img: require("../../assets/images/landing_images/H2.2.png"),
      title: "Gain Indispensable Experience",
      desc: "Participate in real-world projects and solve business problems across multiple industries",
    },
    {
      id: "3",
      img: require("../../assets/images/landing_images/H2.4.png"),
      title: "Effective Self-Pace Learning",
      desc: "Learn at your own pace with support available from our Technical Mentors 24/7",
    },
    {
      id: "4",
      img: require("../../assets/images/landing_images/H2.5.png"),
      title: "Master Job Competencies",
      desc: "Offering a world-class quality curriculum with expert tutorial videos, and holistic learning",
    },
    {
      id: "5",
      img: require("../../assets/images/landing_images/H2.3.png"),
      title: "Maximise Job Opportunities",
      desc: "Market yourself effectively and with the best pay possible through our guided career prep",
    },
    {
      id: "6",
      img: require("../../assets/images/landing_images/H2.6.png"),
      title: "Career Advancement",
      desc: "98% of participants have experienced successful job advancement and pay increases",
    },
  ],
  business: [
    {
      id: "1",
      img: require("../../assets/images/landing_images/business/B2.1.png"),
      title: "Job-focused Training Programmes",
      desc: "Upskill your team and develop new talents in the hardest-to-find skills set according to job domains. Our Zero-to-Hero self-learning model will take your employees from foundation level in a given skill/technology in through to mastery level.",
    },
    {
      id: "2",
      img: require("../../assets/images/landing_images/business/B2.2.png"),
      title: "Customisable Packages",
      desc: "Our world-class quality curriculum, expert tutorial videos, and holistic learning approach will enable help you to master critical competencies for job success, not just skills to do specific certain tasks.",
    },
    {
      id: "3",
      img: require("../../assets/images/landing_images/business/B2.3.png"),
      title: "Master Job Competencies, NOT Just Skills",
      desc: "Our quality curriculum, expert tutorial videos, and holistic learning approach will help you master critical competencies for greater productivity and job outcomes, not just skills to do certain tasks.",
    },
    {
      id: "4",
      img: require("../../assets/images/landing_images/business/B2.4.png"),
      title: "Gain Indispensable Experience",
      desc: "Undertake real-world projects and solve business problems across industry sectors. Learning through experience leads to real job domain mastery and business acumen.",
    },
    {
      id: "5",
      img: require("../../assets/images/landing_images/business/B2.5.png"),
      title: "Effective Self-Pace Learning",
      desc: "Learn at your own pace with support at hands. Our Technical Mentors are available 24/7 to help your learning go in the right direction and make the real impact.",
    },
    {
      id: "6",
      img: require("../../assets/images/landing_images/business/B2.6.png"),
      title: "Managed Experiences & Outcomes",
      desc: "Our Real-time Learning Management Dashboard enables L&D managers to easily manage large-scale upskilling projects.",
    },
  ],
};

export const roadMap = [
  {
    img: require("../../assets/images/landing_images/H6.1.png"),
    title: "Skills and Competencies",
    subtitle: "Learn, practice and quiz anytime, anywhere",
    desc: ["Access top-notch tutorial videos and hands-on exercises to build job-specific skills and competencies"],
  },
  {
    img: require("../../assets/images/landing_images/H6.2.png"),
    title: "Project Experience",
    subtitle: "Become a master in your field with real work scenarios. ",
    desc: ["Work on projects that inspire, challenge and advance your knowledge and expand your capabilities"],
  },
  {
    img: require("../../assets/images/landing_images/H6.3.png"),
    title: "Career Prep",
    subtitle: "Share your strengths to help you attain job offers",
    desc: ["Our Career Prep packages provide the skills and tools you need to communicate accomplishments"],
  },
  {
    img: require("../../assets/images/landing_images/H6.4.png"),
    title: "Personalised Support ",
    subtitle: "The help you need, when you need it",
    desc: ["Our Technical specialist are on hand 24/7 to assist you with questions you have, when you have them"],
  },
];

export const marketLeaders = {
  background: require("../../assets/images/landing_images/H5.png"),
  logos: [
    require("../../assets/images/landing_images/H5.1.png"),
    require("../../assets/images/landing_images/H5.2.png"),
    require("../../assets/images/landing_images/H5.3.png"),
    require("../../assets/images/landing_images/H5.4.png"),
    require("../../assets/images/landing_images/H5.5.png"),
  ],
  logos2: [
    require("../../assets/images/landing_images/M (1).png"),
    require("../../assets/images/landing_images/M (2).png"),
    require("../../assets/images/landing_images/M (3).png"),
    require("../../assets/images/landing_images/M (4).png"),
    require("../../assets/images/landing_images/M (5).png"),
  ],
};

export const strengthInNumbers = [
  {
    id: 1,
    title: "2000+",
    img: require("../../assets/images/landing_images/H7.1.png"),
    desc: "Professionals and Specialists Developed",
  },
  {
    id: 2,
    title: "Over 98%",
    img: require("../../assets/images/landing_images/H7.2.png"),
    desc: "of Delegates Got Jobs or Progressed Their Career Upon Completion",
  },
  {
    id: 3,
    title: "4.7+",
    img: require("../../assets/images/landing_images/H7.3.png"),
    desc: "Customer Satisfaction Score",
  },
];
export const strengthInNumbers_Business = [
  {
    id: 1,
    title: "2000+",
    img: require("../../assets/images/landing_images/business/B5.1.png"),
    desc: "Professionals and Specialists Developed",
  },
  {
    id: 2,
    title: "Over 98%",
    img: require("../../assets/images/landing_images/business/B5.2.png"),
    desc: "of Delegates Got Jobs or Progressed Their Career Upon Completion",
  },
  {
    id: 3,
    title: "4.7+",
    img: require("../../assets/images/landing_images/business/B5.3.png"),
    desc: "Customer Satisfaction Score",
  },
];
export const businessStrength = [
  {
    id: 1,
    title: "38%",
    img: require("../../assets/images/landing_images/business/B4.1.png"),
    desc: "Revenue boost by 2022",
  },
  {
    id: 2,
    title: "90%",
    img: require("../../assets/images/landing_images/business/B4.2.1.png"),
    desc: "the proportion of worker time potentially impacted by intelligent technologies",
  },
  {
    id: 3,
    title: "$11.5T",
    img: require("../../assets/images/landing_images/business/B4.3.png"),
    desc: "economic growth driven by intelligent technologies that could be forgone if talent development and skill building fails to catch up",
  },
];

export const businessRoadMap = [
  {
    img: require("../../assets/images/landing_images/business/B3.1.png"),
    title: "Pre-assessments",
    subtitle: "Ensures the right training programme for your team.",
    desc: "Test your current skills level and address the skills gaps. Ensure programmes are aligned with the right learning path for your organisation and individual’s development needs.",
  },
  {
    img: require("../../assets/images/landing_images/business/B3.2.png"),
    title: "Skills and Competences",
    subtitle: "Learn – Practice – Quiz. Anytime. Anywhere.",
    desc: "Our top-notch tutorial videos and hands-on exercises enable you to build job-focused skills and competencies.",
  },
  {
    img: require("../../assets/images/landing_images/business/B3.3.png"),
    title: "Project Experience",
    subtitle: "Put your skills in real work. Achieve real job domain mastery.",
    desc: "Work on Projects that inspires, challenges and advances your knowledge. Solve business problems, enrich your profile and get ahead of the competition.",
  },
  {
    img: require("../../assets/images/landing_images/business/B3.4.png"),
    title: "Personalised Support 24/7",
    subtitle: "The help you need, when you need it",
    desc: "Reach out to our Technical Mentors 24/7 and have your coding questions answered quickly so you can keep learning.",
  },

  {
    img: require("../../assets/images/landing_images/business/B3.5.png"),
    title: "Real-time Management Dashboard",
    subtitle: "Easily manage large-scale upskilling initiatives.",
    desc: "A suite of innovative tools enable L&D managers to easily manage individual learning progress. Set deadlines. View your team’s progress. Know their competencies. Everything is at your fingertips.",
  },
];

export const careerPathsProjects = [
  {
    id: 1,
    title: "Data Analyst",
    projects: [
      {
        id: 1,
        name: "Project 1",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 2,
        name: "Project 2",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 3,
        name: "Project 3",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
    ],
  },
  {
    id: 2,
    title: "Data Scienctist ",
    projects: [
      {
        id: 1,
        name: "Project 1",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 2,
        name: "Project 2",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 3,
        name: "Project 3",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
    ],
  },
  {
    id: 3,
    title: "Expert Data Scienctist ",
    projects: [
      {
        id: 1,
        name: "Project 1",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 2,
        name: "Project 2",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
      {
        id: 3,
        name: "Project 3",
        desc: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse corrupti ullam aliquid consequatur culpa possimus nobis totam abdolore aperiam dolor eaque enim voluptas, id nostrum consequuntur at, minus earum!",
        img: "",
      },
    ],
  },
];

export const membershipIntro = {
  individual: {
    title: "Our Memberships",
    label: "Individual Membership Intro",
    desc: "As a member, you receive access to all our exclusive courses, career paths, features, and content. Make your learning comprehensive and easy! Our career services help you achieve the best possible results on your job search",
    background: require("../../assets/images/membership_intro/individual/IM1.png"),
    modules: [
      {
        img: require("../../assets/images/membership_intro/individual/IM2.1.png"),
        title: "What Stack Genie Provides",
        id: "stack-genie",
        features: [
          "Access to all courses across the technology stack with one click",
          " Best value-for-money package on the market ",
          "Interaction tools allowing you to track your learnings progress Innovative Learning Calendar to plan how and when you progress through the courses",
          " Access to thousands of relevant job postings, with search tools at your fingertips",
          " An industry-recognised certificate for every course you complete",
          " Access to a community of learners and tech professionals sharing ideas on projects, best practices and more.",
        ],
        for: "Those who want to learn new, higher-paying job skills",
      },
      {
        img: require("../../assets/images/membership_intro/individual/IM2.2_1.png"),
        title: "What Domain Stack Specialist Provides",
        id: "domain-stack-specialist",
        features: [
          "A combination of tools to meet the increasing demands of expanding career paths ",
          "An all-in-one programme with tools, languages, applications, frameworks, data sources, CV and presentation templates and more ",
          "Industry technology projects, great for your CV, that progress from easier to more complex projects ",
          "A professional certificate, printable upon course completion ",
          "A toolset simplified by industry experts to save you valuable time researching and consulting",
        ],
        for: "Suitable for: Those who want to have a higher-paying job, those who want to change their career to tech, or those who need better and more professional guidance to get into their dream career path",
      },
      {
        img: require("../../assets/images/membership_intro/individual/IM2.3.png"),
        title: "What Stack Guru Provides",
        id: "stack-guru",
        features: [
          "Access to all the career paths on our platform ",
          "Access to all the projects and solutions for each career domain, including problem-solving overviews in a variety of industries",
          " Project experience across industry verticals",
          " A rundown of in-demand tools currently driving the market",
          " An overview of leadership skills to secure a higher position in the technology sector",
        ],
        for: "Suitable for: Experienced specialists who want to advance their skills and work toward a management-level position",
      },
    ],
  },
  enterprise: {
    title: "Our Memberships",
    label: "Secure A New Higher-Paying Job | Top Tech Skills ",
    desc: "We empower your team to thrive at the forefront of the digital revolution. Your employees not only develop the technical skills needed to excel within the tech sphere, but also the knowledge, experience and soft skills to tackle day-to-day tasks within a specific organisation",
    background: require("../../assets/images/membership_intro/enterprise/EM1.png"),
    modules: [
      {
        img: require("../../assets/images/membership_intro/enterprise/EM2.1.png"),
        title: "What Talent Incubator Provides",
        id: "talent-incubator",
        features: [
          "Designed for enterprises of all sizes",
          " Includes tech instruction developed by experts for junior analysts and engineers",
          " Provides organisational tools to build a team of domain experts from scratch across all aspects of data technology",
          " Saves many thousands of dollars in hiring costs by developing in-house talent",
          " Prepares your smartest employees to support your transformation efforts as you prepare for an increasingly digital workplace",
          " Assigns courses to specific employees and takes an overview of their learning progress from your management dashboard. Get an instant idea from the data about who is ready to take a particular job",
        ],
        for: "",
      },
      {
        img: require("../../assets/images/membership_intro/enterprise/EM2.2.png"),
        title: "What Performance Booster Provides",
        id: "performance-booster",
        features: [
          "Suitable for the enterprise of all sizes",
          " Allows your existing employees to up-skill themselves with the latest tools in the industry",
          " Lowers your hiring costs by up-skilling in-house talent with the most up-to-date technology training courses ",
          "Gives you access to a number of emerging projects that boost the problem-solving skills of your employees ",
          "Prepares your smartest employees to support your transformation efforts as you prepare for an increasingly digital workplace ",
          "Assigns courses to specific employees and takes an overview of their learning progress from your management dashboard. Get an instant idea from the data about who is ready to take a particular job",
        ],
        for: "",
      },
    ],
  },
};

export const NetworkErrors = {
  404: {
    message: `Sorry, something went wrong :(`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151126/Error%20pages%20and%20Coming%20soon%20page/404_2_iqonar.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  403: {
    message: `Forbidden error`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151124/Error%20pages%20and%20Coming%20soon%20page/403_bva6zs.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },

  400: {
    message: `Bad Request:(`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  401: {
    message: `Sorry, you are not authorized to access this page :(`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  500: {
    message: `Internal Server Error`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  502: {
    message: `Bad Gateway`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  503: {
    message: `Service Unavailable`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
  504: {
    message: `Gateway Timeout`,
    image:
      "https://res.cloudinary.com/stackwisrlibrary/image/upload/v1611151125/Error%20pages%20and%20Coming%20soon%20page/500_js4civ.png",
    link: {
      url: "/",
      message: "GO TO HOMEPAGE",
    },
  },
};

export const careerServiceData = {
  hero: {
    subtitle: "Career Service",
    title: "Maximise Your Job Opportunities",
    desc: "That much-coveted dream job? You KNOW you’re right for it. StackwisR supplies you the services and support to make sure THEY know it too",
  },
  support: {
    header: "",
    data: [
      {
        title: "Job Research",
        img: require("../../assets/images/career_service/CSM2.1.png"),
      },
      {
        title: "Career Path Tailored CV template",
        img: require("../../assets/images/career_service/CSM2.2.png"),
      },
      {
        title: "Interview Q&A",
        img: require("../../assets/images/career_service/CSM2.3.png"),
      },
      {
        title: "Interview Tips Articles",
        img: require("../../assets/images/career_service/CSM2.4.png"),
      },
    ],
  },
  search: {
    title: "Search for the Top Opportunity",
    desc: "Search out relevant tech jobs on Indeed and apply directly through our website. We provide insights and strategic advice to help you find the positions that match your training and interests",
    data: [
      {
        title: "Data Analyst",
        link: "#",
        img: require("../../assets/images/career_service/1_asqmcf.png"),
      },
      {
        title: "Data Scientist",
        link: "#",
        img: require("../../assets/images/career_service/2_qabn2v.png"),
      },
      {
        title: "Expert Data Scientist",
        link: "#",
        img: require("../../assets/images/career_service/3_jk7yrl.png"),
      },
      {
        title: "Big Data Engineer",
        link: "#",
        img: require("../../assets/images/career_service/4_rulmwk.png"),
      },
      {
        title: "DevOps Practitioner",
        link: "#",
        img: require("../../assets/images/career_service/5_awvbvz.png"),
      },
      {
        title: "Cloud BI Developer",
        link: "#",
        img: require("../../assets/images/career_service/6_aor2my.png"),
      },
    ],
  },
  cvTemplate: {
    title: "Get Tailored CV Template",
    desc: "We provide premium CV carefully templated for your your chosen career path, and tailored by our top-notch CV writers to reflect your own unique strengths. We’ll help showcase the skills, experience and qualifications that clarify your career objectives and earn full consideration from prestigious companies",
    data: [
      {
        title: "Data Scientist",
        thumbnail: require("../../assets/images/resume/resume-template.png"),
      },
      {
        title: "Data Analyst",
        thumbnail: require("../../assets/images/resume/resume-template.png"),
      },
      {
        title: "DevOps",
        thumbnail: require("../../assets/images/resume/resume-template.png"),
      },
      {
        title: "Expert Data Scientist",
        thumbnail: require("../../assets/images/resume/resume-template.png"),
      },
      {
        title: "AI specialist",
        thumbnail: require("../../assets/images/resume/resume-template.png"),
      },
    ],
  },
  interview: {
    title: "Interview Q&A",
    desc: "The StackwisR Q&A cover all the critical questions you’ll encounter in a tech-related job interview. Now you can go in prepared! We give you a collection of beginner, intermediate and advanced level questions to enrich your theoretical and practical knowledge of your career path",
    data: [
      {
        title: "BI Developer Interview Questions",
        thumbnail: require("../../assets/images/career_service/Iv_1.png"),
      },
      {
        title: "Data Analyst Questions",
        thumbnail: require("../../assets/images/career_service/Iv_2.png"),
      },
      {
        title: "DevOps Questions",
        thumbnail: require("../../assets/images/career_service/Iv_3.png"),
      },
      {
        title: "Expert Data Scientist Questions",
        thumbnail: require("../../assets/images/career_service/Iv_4.png"),
      },
      {
        title: "AI specialist Questions",
        thumbnail: require("../../assets/images/career_service/Iv_3.png"),
      },
    ],
  },
  leadershipCourses: {
    title: "Leadership Course",
    desc: "Develop and elevate the collaborative attributes you’ll embody in future leadership roles",
  },
};

/**
 *  Job Mentoring Programme Page Data
 */
export const jmpHeroSlides = [
  {
    id: 1,
    title: "Kickstart Your Career Change Into Tech   ",
    img: require("../../assets/images/jmp/heroS1.png"),
    desc: "Our Job Mentoring Programme offers the most comprehensive career development pathway for fresh graduates and professionals seeking to build and excel in a career in data technology in the world.",
  },
  {
    id: 2,
    title: "Join Our Live Classes ",
    img: require("../../assets/images/jmp/heroS2.png"),
    desc: "Access to our daily live tutorials covering High Performance courses, Project workshop whether you participate in solving complex industry problems and gain incredible experience.",
  },
  {
    id: 3,
    title: "Learn From Our Recruitment Partners ",
    img: require("../../assets/images/jmp/heroS3.png"),
    desc: "Meet recruitment partners in our Executive VIP sessions and gain rare insight into what employers really want so you are as good as hired in your very next interview.",
  },
];

export const jmpBenefit = {
  header: {
    title: "An Industry Mentoring Programme Created With You In Mind ",
    desc: "Explore how our mentoring and professional development programme may be your best career move yet",
  },
  benefits: [
    {
      img: require("../../assets/images/jmp/benefit1.png"),
      title: "LEARN AT YOUR OWN PACE ",
      desc: "Receive certification in your own time, all thanks to our self - paced course content. ",
    },
    {
      img: require("../../assets/images/jmp/benefit2.png"),
      title: "JOIN EXCLUSIVE VIP WEBCAST",
      desc: "Featuring VIP webcasts, specialist mentors, career tips, and more. Accelerate your learning with invaluable support.",
    },
    {
      img: require("../../assets/images/jmp/benefit3.png"),
      title: "RECEIVE SUPPORT WHENEVER",
      desc: "Receive one-to-one support from industry experts when you need it, whether for projects, work experience or interview.",
    },
    {
      img: require("../../assets/images/jmp/benefit4.png"),
      title: "GO BEYOND LEARNING ",
      desc: "Our ultimate goal is for you to receive a well - suited job offer in the tech industry. ",
    },
    {
      img: require("../../assets/images/jmp/benefit5.png"),
      title: "GROW YOUR EMPLOYABILITY ",
      desc: "Capitalise on your potential with our career resources and in -house recruitment service. ",
    },
    {
      img: require("../../assets/images/jmp/benefit6.png"),
      title: "START EARNING MORE ",
      desc: "Enjoy seeing your hard work pay off with new job roles, pay raises, and promotions.",
    },
  ],
};

export const jmpOffersCareerpathPage = {
  header: {
    title: "Mentoring And Professional Development: What We Offer ",
    desc: "For a career change into tech to succeed, you need to know more than industry acronyms and functions. The Job Mentoring Programme provides mentoring and professional development support from the day you sign up to the moment you feel confident in your new role.  ",
  },
  data: [
    {
      img: require("../../assets/images/jmp/features1.png"),
      title: "Exclusive VIP Webcast",
      desc: `Skyrocket your learning with extra content.`,
    },
    {
      img: require("../../assets/images/jmp/features2.png"),
      title: "Real-World Assignments",
      desc: `Apply your course learning to the industry`,
    },
    {
      img: require("../../assets/images/jmp/features3.png"),
      title: "Useful Career Resources",
      desc: `Get your foot in the door as a future leader.`,
    },
    {
      img: require("../../assets/images/jmp/features4.png"),
      title: "Employer References",
      desc: `Impress future employers even more`,
    },
    {
      img: require("../../assets/images/jmp/features5.png"),
      title: "Hands-On Support",
      desc: `Receive all the answers to your questions`,
    },
    {
      img: require("../../assets/images/jmp/features6.png"),
      title: "Follow-Up Support",
      desc: `Get on-the-job support for 30 days`,
    },
  ],
};
export const jmpOffers = {
  header: {
    title: "Mentoring And Professional Development: What We Offer ",
    desc: "For a career change into tech to succeed, you need to know more than industry acronyms and functions. The Job Mentoring Programme provides mentoring and professional development support from the day you sign up to the moment you feel confident in your new role.  ",
  },
  data: [
    {
      img: require("../../assets/images/jmp/offer8.png"),
      title: "Exclusive VIP Webcast",
      desc: `Skyrocket your learning with extra content. 

To stay up-to-date throughout your career change into tech, the Job Mentoring Programme team will host up to 20 hours of webcast sessions per month (worth £1000+). Explore our highly acclaimed VIP webcasts here. `,
    },
    {
      img: require("../../assets/images/jmp/offer7.png"),
      title: "Accelerated Self-Paced Learning",
      desc: `Discover the daily tasks of your future job. 

You will have the opportunity to work through the learning content (with an impressive 55 courses and 365 modules to choose from) at a time that suits you. Evenings, weekends…whenever! `,
    },
    {
      img: require("../../assets/images/jmp/offer6.png"),
      title: "Useful Career Resources",
      desc: `Get your foot in the door as a future leader. 

Our industry mentoring programme aims to help delegates break into the tech space, with tailored CV templates, interview Q&As, and leadership courses available to get you on the right track. `,
    },
    {
      img: require("../../assets/images/jmp/offer5.png"),
      title: "Hands-On Support",
      desc: `Receive all the answers to your questions. 

Are you struggling to grasp a particular module or aspect of your course? Has your project left you feeling confused? Whatever it is, our trainers guarantee to respond to queries in 24-48 hours.`,
    },
    {
      img: require("../../assets/images/jmp/offer4.png"),
      title: "Real-World Assignments",
      desc: `Apply your course learning to the industry. 

You will be tasked with job-specific business projects, completed under the guidance of project managers (or ‘trainers’) on a 1:1 basis and during weekly storm sessions with fellow delegates. `,
    },
    {
      img: require("../../assets/images/jmp/offer3.png"),
      title: "Job Mentoring Programme Certification",
      desc: `Enter the industry with proof of skill. 

People are leaving their existing jobs to join the world of tech faster than ever. Make your job application stand out by displaying an industry mentoring programme certification on your CV. `,
    },
    {
      img: require("../../assets/images/jmp/offer2.png"),
      title: "Employer References",
      desc: `Impress future employers even more. 

You know the fundamentals of the job role and have used them in a real-world project context. The only task left is to ask your project manager to confirm your involvement in a reference letter. `,
    },
    {
      img: require("../../assets/images/jmp/offer1.png"),
      title: "Follow-Up Support",
      desc: `Get on-the-job support for 30 days. 

We understand that you may have some lingering questions – even after getting hired. As long as you still have a Job Mentoring Programme membership, you can attend storm sessions and webcasts to find the answers.`,
    },
  ],
};

export const jmpWhyChange = {
  header: {
    title: "Why Choose Us For Your Career Change Into Tech?",
  },
  data: [
    {
      img: require("../../assets/images/jmp/whyChange1.png"),
      title: "15+ years’ experience in the educational tech space. ",
    },
    {
      img: require("../../assets/images/jmp/whyChange2.png"),
      title: "Knowledge of delegate and industry pain points. ",
    },
    {
      img: require("../../assets/images/jmp/whyChange3.png"),
      title: "Tech specialists on-hand to support learning. ",
    },
    {
      img: require("../../assets/images/jmp/whyChange4.png"),
      title: "Past recordings available to help with assignments.",
    },
    {
      img: require("../../assets/images/jmp/whyChange5.png"),
      title: "Frequent webcasts and storm sessions to enrich learning. ",
    },
    {
      img: require("../../assets/images/jmp/whyChange6.png"),
      title: "Professional recruitment service to land job roles. ",
    },
  ],
};

export const jmpHowProgrammeWorks = {
  header: {
    title: "How Our Industry Mentoring Programme Works",
  },
  data: [
    {
      title: "STAGE 1",
      desc: "Decide on your career path and learn the various software. ",
      img: require("../../assets/images/jmp/hw1.png"),
    },
    {
      title: "STAGE 2",
      desc: "Complete real-life business projects and boost your CV. ",
      img: require("../../assets/images/jmp/hw2.png"),
    },
    {
      title: "STAGE 3",
      desc: "Use our recruitment service to secure your ideal job role! ",
      img: require("../../assets/images/jmp/hw3.png"),
    },
  ],
};

export const jmpVipWebcasts = {
  header: {
    title: "VIP Webcasts: The Heart Of Our Industry Mentoring Programme",
    desc: "The Job Mentoring Programme includes up to 20 hours of VIP webcasts per month, worth over £1000 in value. Delegates are welcome to attend as many webcasts as they deem necessary to bolster their learning and career preparation.",
  },
  data: [
    {
      title: "High Performance Tutorials Classes",
      summary: "Live classes designed to cover theoretical and practical topics",
      img: require("../../assets/images/jmp/webcast1.png"),
      desc: `This Exclusive session covers theoretical and practical topics that are usually harder to understand for most learners within tools and programmes. This series is a mentor tutorial on a subject matter within a course or a topic. Some of the sessions will be hands-on and allow for learner participation. 

Example: 
&#9679 Data Scientists looking to understand the mechanics of machine learning at a deeper level or for Data Engineers looking to grasp Data Fabric architectures and automated CI/CD pipelines for downstream AI applications 
&#9679 Data scraping using Python modules that can be used to scrape web pages and a hands-on experience of doing it yourself 
&#9679 Learning to write simple to complex SQL queries and stored procedures 
 
      `,
    },
    {
      title: "Immersive Project Workshop",
      summary: "Project workshops solving real-life industry problems",
      img: require("../../assets/images/jmp/webcast2.png"),
      desc: `These sessions are designed for collaborative solution lab work. It delves into some of the more complex projects within the Pairview and StackwisR portfolio, from problem framing and hypothesis to scripting and presenting. We will pick a use case and solve it using different tools and technologies as you would do in an actual project. 

Example: 
&#9679 Build a Blockchain-based solution for a Car manufacturer to bring their dealerships, service centres and repair centres on a blockchain network. 
&#9679 Use Hyperledger Fabric to set up a blockchain network 
&#9679 Write application in React 
&#9679 Write unit test cases of application in Mocha 
&#9679 Write smart contracts and deploy in chaincode on a channel 
&#9679 Use private data in Fabric 
&#9679 Securing various assets on the blockchain 
&#9679 Auth and permissions for every actor on the network 
&#9679 Adding orgs to your channel 
&#9679 Writing chain code 


      `,
    },
    {
      title: "Exclusive VIP Booster Series ",
      summary: "Develop leadership skills in technology and prepare for the future of executive participation ",
      img: require("../../assets/images/jmp/webcast3.png"),
      desc: `The Exclusive VIP Booster Series aims to develop leadership skills in technology people and prepare them for the future of executive participation and accelerate success for and on the job. Our internal team or partners will host this series with speakers from the tech recruitment world, management schools, IBM, Microsoft, Lumen, Section or AWS, etc. 

Example:
&#9679 Market Trends and Emerging Technology (How to prepare for the next wave) 
&#9679 Managing Technology (Sub-tops various tech) Projects, Stakeholders and Teams. 
&#9679 Winning hearts and minds through the interviewing process. 
&#9679 Working remotely in complex, fast-paced and highly diverse environments. 
&#9679 Delivering High Impact Presentations when the stakes are high. 
&#9679 Ready for the Job market? Dynamics of Tech Recruitment processes and how to be the lead candidate.

      `,
    },
    {
      title: "Tactical Support STORM Sessions",
      summary: "On-demand support for technical issues on the career path or courses  you take",
      img: require("../../assets/images/jmp/webcast4.png"),
      desc: `Tactical Support STORM Sessions are live support sessions where experts and mentors aim to resolve tickets for the benefit of everyone who may be dealing with such issues. 

Examples:
&#9679 Platform issues will be discussed and resolved where possible 
&#9679 Software, code and problems encountered in courses may also be discussed and resolved. 
&#9679 The StackwisR team will announce new features that are being released. Customers will be able to see a demo of how to navigate these features on the platform, etc.

      `,
    },
  ],
};

export const jmpCta = {
  title: "Sign up for our industry mentoring programme and land your dream role in no time.",
  desc: "Self-paced learning, mentoring and professional development, career resources…everything you could possibly need! ",
  button: "BOOK A CALL",
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  { code: "AE", label: "United Arab Emirates", phone: "971" },
  { code: "AF", label: "Afghanistan", phone: "93" },
  { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  { code: "AU", label: "Australia", phone: "61", suggested: true },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "387" },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  { code: "CA", label: "Canada", phone: "1", suggested: true },
  { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
  { code: "CD", label: "Congo, Democratic Republic of the", phone: "243" },
  { code: "CF", label: "Central African Republic", phone: "236" },
  { code: "CG", label: "Congo, Republic of the", phone: "242" },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  { code: "DE", label: "Germany", phone: "49", suggested: true },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  { code: "DO", label: "Dominican Republic", phone: "1-809" },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500" },
  { code: "FM", label: "Micronesia, Federated States of", phone: "691" },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  { code: "FR", label: "France", phone: "33", suggested: true },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  { code: "HM", label: "Heard Island and McDonald Islands", phone: "672" },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
  { code: "IQ", label: "Iraq", phone: "964" },
  { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  { code: "JP", label: "Japan", phone: "81", suggested: true },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
  { code: "KP", label: "Korea, Democratic People's Republic of", phone: "850" },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  { code: "MD", label: "Moldova, Republic of", phone: "373" },
  { code: "ME", label: "Montenegro", phone: "382" },
  { code: "MF", label: "Saint Martin (French part)", phone: "590" },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  { code: "PS", label: "Palestine, State of", phone: "970" },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  { code: "ST", label: "Sao Tome and Principe", phone: "239" },
  { code: "SV", label: "El Salvador", phone: "503" },
  { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
  { code: "SY", label: "Syrian Arab Republic", phone: "963" },
  { code: "SZ", label: "Swaziland", phone: "268" },
  { code: "TC", label: "Turks and Caicos Islands", phone: "1-649" },
  { code: "TD", label: "Chad", phone: "235" },
  { code: "TF", label: "French Southern Territories", phone: "262" },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
  { code: "TV", label: "Tuvalu", phone: "688" },
  { code: "TW", label: "Taiwan, Province of China", phone: "886" },
  { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  { code: "US", label: "United States", phone: "1", suggested: true },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
  { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784" },
  { code: "VE", label: "Venezuela", phone: "58" },
  { code: "VG", label: "British Virgin Islands", phone: "1-284" },
  { code: "VI", label: "US Virgin Islands", phone: "1-340" },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];
