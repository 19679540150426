import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { footerData } from "../utilites/constants";
import footerLogo from "../../assets/images/footerLogo.png";
import { socialData } from "../utilites/constants";
import { HashLink } from 'react-router-hash-link';
import "./footer.css";
import { useUserCountry } from "../../context/userCountryContext";
import { useGetCountriesQuery } from "../../graphql/operations/queries/getCountries"
import { useHistory } from "react-router-dom";

const Footer = () => {
  const {location:{pathname}} = useHistory();
 const isCheckout = pathname === '/checkout'
  const { data: { countries } = {} } = useGetCountriesQuery()
  const { country, setCountry } = useUserCountry();

  const onChange = (e) => {
    const newCountry = countries.find(c => c.name === e.target.value);
    setCountry(newCountry);
  }

  return (
    <div className="footerWrapper">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={1} sm={6}></Grid>
          {Object.keys(footerData).map((data, index) => {
            return (
              <Grid item xs={12} md={2} sm={6} key={index}>
                <h3>{data}</h3>
                <List>
                  <>
                    {footerData[data].map((d, ind) => {
                      return (
                        <HashLink to={d.link} key={ind}>
                          <ListItem >
                            <ListItemText primary={d.title}></ListItemText>
                          </ListItem>
                        </HashLink>
                      );
                    })}
                    {
                      !isCheckout &&  (
                    <div className="country-select">
                      {Object.keys(footerData).length === index + 1 && (
                        <select onChange={onChange} value={country?.name}>
                          <option>Select Country</option>
                          {countries?.map(c => (
                            <option key={c.name}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                      )
                    }
                  </>
                </List>
              </Grid>
            );
          })}
          <Grid item xs={12} md={1} sm={6}></Grid>
        </Grid>

        <div className="footerSection">
          <p className="footerBottom">
            <img src={footerLogo} alt="" />
            Copyright © 2020 Pairview Limited. All rights reserved
          </p>
          <div className="footerTerms">
            <a href="/terms-and-conditions">
              <p>Terms of Use</p>
            </a>
            <a href="/privacy-policy">
              <p>Privacy Policy</p>
            </a>

          </div>
          <div className="socialLogo" >
            {socialData.map((data, index) => {
              return (
                <a href={data.link} key={index}>
                  <img src={data.img} alt="" />
                </a>
              );
            })}
          </div>
        </div>
      </Container >
    </div >
  );
};

export default Footer;
