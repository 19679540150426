import PropTypes from "prop-types";
import React from 'react'
import Lottie from 'react-lottie'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import search from '../../assets/lottieFiles/5451-search-file.json'
import error from '../../assets/lottieFiles/lf30_editor_yoauhyxy.json'
import certificate from '../../assets/lottieFiles/certificate.json'
import { Button } from "../button/Button";

const EmptyCardMessage = ({
    message = "No active selection",
    button: {
        message: btnMessage = 'Add Now',
        link,
        onClick,
    } = {},
    type = 'search',
    classes: {
        lottieWrapper,
        lottieLink,
        lottieBtn
    } = {},
    style: {
        width,
        height,
    } = {},
    animation,
}) => {

    const defaultOptions = {
        loop: type === 'certificate' ? false : true,
        autoplay: true,
        animationData: animation ? animation : type === 'error' ? error : type === 'certificate' ? certificate : search,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <LottieWrapper type={type} className={`${lottieWrapper}`}>
            <Lottie
                options={defaultOptions}
                height={height ? height : type === 'error' ? 140 : type === 'certificate' ? 200 : 100}
                width={width ? width : type === 'error' ? 140 : type === 'certificate' ? 200 : 100}
            />
            <h5 className={type === 'error' ? 'lottie-err' : ``}>{message}</h5>
            {link && (
                <LottieLink to={link} className={`${lottieLink}`} >{btnMessage}</LottieLink>
            )
            }
            {onClick && (
                <LottieBtn onClick={onClick} className={`${lottieBtn}`}>{btnMessage}</LottieBtn>
            )
            }
        </LottieWrapper>
    )
}

EmptyCardMessage.propTypes = {
    animation: PropTypes.any,
    message: PropTypes.string,
    type: PropTypes.string,
    classes: PropTypes.shape({
        lottirBtn: PropTypes.string,
        lottieLink: PropTypes.string,
        lottieWrapper: PropTypes.string,
    }),
    style: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
}

export default EmptyCardMessage;

const LottieWrapper = styled.div`
    display:flex;
    flex-direction:column;
    padding: 20px 0;
    
    h5{
        font-weight:500;
        text-align:center ; 
        font-size: 16px;
        margin-bottom: 21px;  
        color:#ffffffd6;
        letter-spacing: 0.7px;
        &.lottie-err{
            text-transform: uppercase;
        }
    }
    p{
        text-align:center ;   
        color:"ffffffd6";
    }
    
    svg{
        margin-left:${p => p.type === 'certificate' ? '-10px !important' : ''};
    }
   
`;
const LottieLink = styled(Link)`
    &&{
        margin:10px auto;
        width: 133px;
        font-size: 12px;
        padding: 10px;
        box-shadow: 0 0 0px #ffffff30;
        box-shadow: 0px 0px 2px 2px #ffffff30;
        border-radius: 10px;
        color: #ffffffd6;
        border: solid #ffffffd6 2px;
        text-decoration: none;
        text-align: center;

        :hover{
            box-shadow: 0px 0px 2px 4px #ffffff30;
        }
    }

`;
const LottieBtn = styled(Button)`
    &&{
        margin:10px auto;
        width: 133px;
        font-size: 12px;
        padding: 10px;
        box-shadow: 0 0 0px #ffffff30;
        box-shadow: 0px 0px 2px 2px #ffffff30;
        border-radius: 10px;
        color: #ffffffd6;
        border: solid #ffffffd6 2px;
        text-decoration: none;
        text-align: center;

        :hover{
            box-shadow: 0px 0px 2px 4px #ffffff30;
        }
    }

`;