import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_CART_ITEMS = gql`
  query GetCartItems {
    cart @client
  }
`;

export const useGetCartItems = () => useQuery(GET_CART_ITEMS);
