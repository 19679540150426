import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './AdBanner.css'
import { useCookies } from 'react-cookie';

//components
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar, Card, CardContent, CardHeader, IconButton, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';

//assest 
import paperPlane from '../../assets/images/AdBanner/paper-plane.png'
import { useGetSubscriptionQuery } from '../../graphql/operations/queries/user/getSubscription';
import { SubscriptionStatus } from '../../Constants/uiConstants';


const AdBanner = (props) => {
  const classes = useStyles();
  const domRef = React.useRef();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:600px)');
  const [isVisible, setVisible] = React.useState(false);
  const [cookies,] = useCookies(['_cookies']);
  const { data: { subscription: { status } = {} } = {}, loading: statusLoading = true } = useGetSubscriptionQuery()



  React.useEffect(() => {
    handleFadeIn();
  }, []);

  // Check if user SubscriptionData matches data in JWT Cookies 
  // if not matched logout
  React.useEffect(() => {
    // if (!statusLoading && !error && (status !== isSubscribed && isSubscribed !== null)) {
    //   history.go()
    // }

  }, [statusLoading]);

  const handleFadeIn = () => {
    const timer = setTimeout(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      if (domRef.current) {
        observer.observe(domRef.current);
      }
      return () => domRef.current ? observer?.unobserve(domRef.current) : '';
    }, 180000)
    return () => clearTimeout(timer);
  }

  const handleClick = () => {
    domRef.current.classList.remove('fade-in-section');
    domRef.current.classList.add('fade-out-section');
    // const timer = setTimeout(() => {
    //   if (domRef.current) {
    //     domRef.current.classList.add('fade-in-section');
    //     domRef.current.classList.remove('fade-out-section');
    //   }
    //   handleFadeIn();
    // }, 120000)
    // return () => clearTimeout(timer);

  }


  const content = {
    'header': 'START YOUR FREE TRIAL',
    'description': 'Make Your Dream Role A Reality!',
    'primary-action': 'Start Now',
    ...props.content
  };

  return (
    <>
      {
        cookies['_cookies'] !== undefined && !statusLoading && !(Object.values(SubscriptionStatus).includes(status)) && (
          <>
            {!matches ? (
              <Box position="fixed" bottom={16} zIndex="modal" ref={domRef} className={`${classes.root} fade-in-section ${isVisible ? 'is-visible' : ''}`} >
                <Container maxWidth="xs" className={classes.container} >
                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <IconButton onClick={handleClick} id='close-ad'>
                          <CloseIcon />
                        </IconButton>
                      }
                    />
                    <Avatar src={paperPlane} className={classes.adIcon} />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h5" component="h2" gutterBottom={true} className={classes.header}>{content.header}</Typography>
                      <Typography variant="subtitle1" color="textSecondary" paragraph={true}>{content.description}</Typography>
                      <Button variant="contained"
                        color="primary"
                        id='pricing-plan'
                        className={classes.action1}
                        onClick={() => history.push('/pricing-plan')}
                      >
                        {content['primary-action']}
                      </Button>
                    </CardContent>
                  </Card>
                </Container>
              </Box>
            ) : (
              <Box position="fixed" bottom={0} zIndex="modal" ref={domRef} className={`${classes.root} fade-in-section ${isVisible ? 'is-visible' : ''}`} >
                <Container maxWidth="xs" className={classes.container} >

                  <Card className={classes.card}>
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <IconButton onClick={handleClick} id='close-ad'>
                          <CloseIcon />
                        </IconButton>
                      }
                      title={content.header}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography variant="subtitle1" paragraph={true}>{content.description}</Typography>
                      <Button variant="contained"
                        className={classes.action1}
                        onClick={() => history.push('/pricing-plan')}
                        id='pricing-plan'
                      >
                        {content['primary-action']}
                      </Button>
                    </CardContent>
                  </Card>
                </Container>
              </Box>
            )}
          </>
        )
      }
    </>
  );
}

export default AdBanner;

const useStyles = makeStyles((theme) => ({
  root: {
    '@media screen and (max-width:600px)': {
      width: '100%',
    },
  },
  container: {
    marginLeft: theme.spacing(0),
    '@media screen and (max-width:600px)': {
      margin: 0,
      padding: 0,
    },
  },
  card: {
    position: 'relative',
    backgroundColor: "#000215",
    padding: theme.spacing(3),
    boxShadow: '0px -7px 35px 0px #359be317',
    backgroundSize: "388%",
    backgroundPosition: "100% 66%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.43'%3E%3Cpolygon fill='%23272839' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%231d1e30' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23141527' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%230a0b1e' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23000215' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E"),
    linear-gradient(360deg, #2995e294, #161525)`,

    "& p ": {
      color: '#afadad',
    },
  },
  cardContent: {
    padding: "0px !important",
    textAlign: 'center',
    '@media screen and (max-width:600px)': {
      padding: "0px !important",

      "& p": {
        textAlign: 'left',
      }

    },
  },
  cardHeader: {
    color: '#fff',
    padding: "0px",
    position: 'absolute',
    right: '15px',
    top: '10px',
    "& .MuiIconButton-label": {
      color: "#fff",
    },
    "& .MuiSvgIcon-root": {
      width: '0.9em',
      height: '0.9em',
    },
    '@media screen and (max-width:600px)': {
      position: 'initial',
      "& .MuiTypography-root ": {
        fontSize: '0.9em',
      },
    },
  },
  header: {
    color: "#fff",
    fontSize: '16px',
  },
  action1: {
    width: '100%',
    marginTop: 0,
    background: '#2995e2',
    "&:hover": {
      background: '#2995e2b5',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  adIcon: {
    margin: '5px auto 10px auto',
    width: '60px',
    height: '60px',
    borderRadius: '0',
  },
}));