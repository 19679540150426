import React from 'react';
import { APPLICATION_ENVS, CURRENT_ENV } from '../../app.config';
import ErrorDispacher from '../../globalHelpers/ErrorDispacher';
import NotFound from '../notFound/NotFound';


export default class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(err) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        ErrorDispacher(error, errorInfo, null, null, true)
    }

    render() {
        if (this.state.hasError && CURRENT_ENV !== APPLICATION_ENVS.LOCAL) {
            return (
                <NotFound
                    type="error"
                    message="Sorry there was a problem loading this page"
                    button={{
                        message: 'Try Again',
                        onClick: () => window.location.reload()
                    }}
                />
            );
        }
        return (
            this.props.children
        )
    }
}