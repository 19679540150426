import { gql, useQuery } from '@apollo/client';


const JWT_QUERY = gql`
query getJwt{
  jwt @client
}
`;


export const useGetJwt = () => useQuery(JWT_QUERY);
