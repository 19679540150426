import React, { createContext, useContext, useState, useEffect } from "react";
import { client } from "../graphql/config/apolloClient";

const UserCountryContext = createContext({
  country: undefined,
  changeCountry: (country) => {},
});

const UserCountryProvider = (props) => {
  const [country, setcountry] = useState(undefined);

  const setCountry = async (country) => {
    if (country) {
      localStorage.setItem("country", JSON.stringify(country));
      setcountry(country);
    } else {
      localStorage.removeItem("country");
      setcountry(undefined);
    }

    // clear field affected by this change from cache
    await client.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: "pricingplans" });
        cache.evict({ fieldName: "pricingplan" });
      },
    });
  };

  useEffect(() => {
    const localCountry = localStorage.getItem("country");

    if (localCountry) {
      setcountry(JSON.parse(localCountry));
    }
  }, []);

  const value = { country, setCountry };
  return (
    <UserCountryContext.Provider value={value}>
      {props.children}
    </UserCountryContext.Provider>
  );
};

export default UserCountryProvider;

export const useUserCountry = () => useContext(UserCountryContext);
