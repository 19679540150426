import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CookiesProvider } from 'react-cookie';
//env
import { LOGROCKET_ID, SENTRY_DNS, CURRENT_ENV, APPLICATION_ENVS } from './app.config'


import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/config/apolloClient';
import { handleJwt } from "./graphql/config/auth";

//issue tracking and error report
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import UserCountryProvider from "./context/userCountryContext";


const activateDebugger = () => {

  //intialize sentry
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    release: "Stackwisr@" + process.env.npm_package_version,
    environment: CURRENT_ENV,
    tracesSampleRate: 1.0,
  });

  //initialize logRocket
  LogRocket.init(LOGROCKET_ID, {
    dom: {
      textSanitizer: [APPLICATION_ENVS.PREPROD, APPLICATION_ENVS.PROD].includes(CURRENT_ENV) ? true : false,
      inputSanitizer: [APPLICATION_ENVS.PREPROD, APPLICATION_ENVS.PROD].includes(CURRENT_ENV) ? true : false,
    },
  });
  setupLogRocketReact(LogRocket);

  //get user details
  const [_, getJwt, __, ___] = handleJwt();
  const { userId, usertype, planId, subscriptionStatus, chargeOptionId } = getJwt() ? JSON.parse(getJwt()) : {};

  // send  user details to log rocket
  LogRocket.identify(userId, {
    name: userId,
    email: userId,

    userType: usertype,
    planId: planId,
    chargeOptionId: chargeOptionId,
    subscriptionStatus: subscriptionStatus
  });

  // link sentry issue to logRocket session
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
      scope.setExtra("userId", userId);
      scope.setExtra("planId", planId);
      scope.setExtra("chargeOptionId", chargeOptionId);
      scope.setExtra("subscriptionStatus", subscriptionStatus);
      scope.setExtra("environment", CURRENT_ENV);
    });
  });

}

[APPLICATION_ENVS.PREPROD, APPLICATION_ENVS.PROD, APPLICATION_ENVS.STAGING].includes(CURRENT_ENV) && activateDebugger();


ReactDOM.render((
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <UserCountryProvider>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </UserCountryProvider>
    </ApolloProvider>
  </ErrorBoundary>
),
  document.getElementById("root")
);


