import PropTypes from "prop-types";
import React from 'react'
import styled from 'styled-components'
import { Avatar } from '@material-ui/core';

// gql
import { useGetUserIdQuery } from '../../graphql/operations/queries/user/getProfile'

function UserAvatar({ type = '', ...rest }) {
    const { data: { userId } = {} } = useGetUserIdQuery()
    return (
        userId ? (
            <StyledAvatar src={`https://avatars.dicebear.com/api/identicon/${userId || 1}.svg`} type={type} {...rest} />
        ) : (
            <StyledAvatar type={type} {...rest} />
        )
    )
}

UserAvatar.propTypes = {
    type: PropTypes.oneOf(['mini', 'nav', 'dashboard', '']).isRequired,
}

export default UserAvatar;


const StyledAvatar = styled(Avatar)`
&&{
        height: ${p => p.type === "mini" || p.type === "nav" ? "30px" : "140px"};
    width: ${p => p.type === "mini" || p.type === "nav" ? "30px" : "140px"};
    border: solid 2px #3590e1;
    box-shadow: 6px 6px 10px #00000029;
    border-radius: 50%;

        @media screen and (max-width: 768px) {
        ${p => p.type !== "nav" && (`
                // height: 100px !important;
                // width: 100px !important;
                `)}
        ${p => p.type === "dashboard" && (`
                height: 80px !important;
                width: 80px !important;
                `)}
        }
}
`;
