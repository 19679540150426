import { InMemoryCache } from "@apollo/client";
import { JWT } from "./auth";
import * as Cookies from "js-cookie";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar();
        },
        userId() {
          return userIdVar();
        },
        jwt() {
          return jwtVar();
        },
        learningState() {
          return learningStateVar();
        },
        learningPlayerState() {
          return learningPlayerStateVar();
        },
        cart() {
          return cartVar();
        },
        cartMessage() {
          return cartMessageVar();
        },
        alertMessage() {
          return alertMessageVar();
        },
        globalError() {
          return globalErrorVar();
        },
        refetchUserCourses() {
          return refetchUserCoursesVar();
        },
        refetchUserCareerpath() {
          return refetchUserCareerpathVar();
        },
        refetchNotUserCourses() {
          return refetchNotUserCoursesVar();
        },
        refetchNotUserCareerpath() {
          return refetchNotUserCareerpathVar();
        },
        refetchUserTopics() {
          return refetchUserTopicsVar();
        },
        notUserCourses: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        notUserCareerPaths: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

//Auth state
export const isLoggedInVar = cache.makeVar(
  Cookies.get(JWT) ? !!JSON.parse(Cookies.get(JWT)).token : false
);
export const userIdVar = cache.makeVar(
  Cookies.get(JWT) && JSON.parse(Cookies.get(JWT)).userId
);
export const jwtVar = cache.makeVar(
  Cookies.get(JWT) && JSON.parse(Cookies.get("_jt_ac"))
);

//cart state
export const cartMessageVar = cache.makeVar("");
export const cartVar = cache.makeVar(
  localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
);

// learning state
export const learningStateVar = cache.makeVar();
export const learningPlayerStateVar = cache.makeVar();

//message
export const alertMessageVar = cache.makeVar();
export const globalErrorVar = cache.makeVar();

//query refetch functions
export const refetchUserCoursesVar = cache.makeVar();
export const refetchUserCareerpathVar = cache.makeVar();
export const refetchNotUserCoursesVar = cache.makeVar();
export const refetchNotUserCareerpathVar = cache.makeVar();
export const refetchUserTopicsVar = cache.makeVar();
