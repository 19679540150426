import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { createBrowserHistory } from "history";
import Layout from "./components/layout/Layout";
import * as variables from "./app.config";

export const history = createBrowserHistory();

const App = () => {
  // console.log(process.env);
  // console.log("variables", variables);

  return (
    <Router>
      <Layout history={createBrowserHistory()} />
    </Router>
  );
};
export default App;
