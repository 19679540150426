import { gql, useQuery } from "@apollo/client";
import {
  PricingPlanFragment,
  ChargeOptionsFragment,
} from "../../fragments/pricingPlanFragment";
import { DASHBOARD_ACCESS_TYPES } from "../../../../Constants/uiConstants";
import { client } from "../../../config/apolloClient";
import { useGetUserIdQuery } from "./getProfile";
import { SubscriptionStatus } from "../../../../Constants/uiConstants";
import { handleJwt, JWT } from "../../../config/auth";

const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions($userId: Int!) {
    subscriptions(userId: $userId) {
      id
      expiry
      status
      trialStatus
      paymentGatewayCustomerId
      planEndDate
      pricingPlan {
        ...PricingPlanFragment
      }
      chargeOption {
        ...ChargeOptionsFragment
      }
    }
  }
  ${PricingPlanFragment}
  ${ChargeOptionsFragment}
`;

export const useGetSubscriptionsQuery = () => {
  const { data: { userId } = {} } = useGetUserIdQuery();

  const skip = !Boolean(userId);
  const subData = useQuery(GET_SUBSCRIPTIONS, {
    variables: { userId },
    skip,
  });

  // update expiry status
  const { data, loading, error, ...rest } = subData;

  if (!loading && !error && data) {
    const updated = data.subscriptions?.map((subscription) => {
      const isNotExpired =
        new Date(subscription.expiry).getTime() > new Date().getTime();

      const newStatus = isNotExpired
        ? subscription.status
        : SubscriptionStatus.EXPIRED;

      //update cookies jwt status
      if (
        Object.values(DASHBOARD_ACCESS_TYPES).includes(
          subscription?.pricingPlan?.accessType
        )
      ) {
        const [, , , updateJwt] = handleJwt();
        updateJwt({ subscriptionStatus: newStatus });
      }

      return {
        ...subscription,
        status: newStatus,
      };
    });

    const newSubData = {
      data: { subscriptions: updated },
      loading,
      error,
      ...rest,
    };
    // console.log("updated", newSubData);

    return newSubData;
  }

  return subData;
};

/**
 * Returns a single membersibe subcription without addon types
 */
export const useGetSubscriptionQuery = () => {
  const subscriptionData = useGetSubscriptionsQuery();
  const { data: { subscriptions } = {}, ...rest } = subscriptionData;

  const subscription = subscriptions?.find((subscription) =>
    Object.values(DASHBOARD_ACCESS_TYPES).includes(
      subscription?.pricingPlan?.accessType
    )
  );

  const newObject = {
    ...rest,
    data: {
      subscriptions,
      subscription,
    },
  };

  return newObject;
};

export const useGetUserPlan = () => {
  const { data: { subscription } = {} } = useGetSubscriptionQuery();

  return subscription?.pricingPlan?.title;
};

export const clearSubscriptionField = async () => {
  // clear subscription data
  await client.cache.evict({ fieldName: "subscriptions" });
  await client.cache.gc();
};
