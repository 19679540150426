import { ApolloClient, gql, useMutation } from "@apollo/client";
import { client } from "../../config/apolloClient";
import { JWT } from "../../config/auth";
import * as Cookies from "js-cookie";


export const REFRESH_TOKEN = gql`
    mutation refreshToken($refreshToken:String!){
        refreshToken(refresh: $refreshToken){
            ok
            access
          }
}
`;


/**
 * `getNewToken` Mutation.
 * `gets a new access token`
 */
export const getNewToken = () => {
    console.log("called refresh ", Cookies.get(JWT) && {
        currentAccess: JSON.parse(Cookies.get(JWT)).token,
        currentRefresh: JSON.parse(Cookies.get(JWT)).refreshToken
    })

    const refreshToken = Cookies.get(JWT) && JSON.parse(Cookies.get(JWT)).refreshToken;
    return client.mutate({ mutation: REFRESH_TOKEN, variables: { refreshToken } }).then((response) => {
        // extract your accessToken from your response data and return it
        const { refreshToken: { access: accessToken } = {} } = response.data;
        return accessToken;
    });
};

