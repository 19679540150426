import { gql, useQuery } from "@apollo/client";
import { createRoute } from "../../../globalHelpers/routeHelpers";

export const GET_CAREER_PATHS = gql`
  query getAllCareerPaths {
    careerpaths {
      id
      title
      shortDesc
      longDesc
      willLearnDesc
      preReqDesc
      headerImg
      thumbnail
    }
  }
`;

export const useGetCareerPathsQuery = () => {
  const { data, loading, error } = useQuery(GET_CAREER_PATHS);
  const all = useQuery(GET_CAREER_PATHS);

  if (!loading && !error) {
    const newArr = [];
    const arr = data.careerpaths;
    for (let i = 0; i < arr?.length; i++) {
      const title = arr[i].title;
      const id = arr[i].id;

      //create route from title
      const route = createRoute(title, `/careerpath/${id}/`);

      // copy and add route to new array
      newArr.push({ ...arr[i], route });
    }

    const dataWithRoutes = { careerpaths: newArr };
    return { ...all, dataWithRoutes };
  }

  //return the data from `sever` and `dataWithRoutes` as undefined

  return { ...all, dataWithRoutes: undefined };
};
