import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, Snackbar } from "@material-ui/core";
import { useGetCartItems } from '../../graphql/operations/queries/getCartItems';


export default function CartMessage() {
    const classes = useStyles();
    const { data: { cart = [] } } = useGetCartItems();
    const [currSize, setcurrSize] = React.useState();
    const [currCart, setcurrCart] = React.useState();
    const [state, setState] = React.useState({ open: false, message: '' });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        // setOpen(false);
        setState({ ...state, open: false });
    };
    React.useEffect(() => {
        setcurrSize(cart.length)
        setcurrCart(cart)
    });

    React.useEffect(() => {
        // console.log(cart, "currSize useEff", currCart)
        if (currSize !== cart.length) {
            if (cart.length > currSize) {
                setState({ message: 'Added to Cart', open: true });
            }
            else if (cart.length < currSize) {
                setState({ message: 'Removed from Cart', open: true });
            }
            else {
                setState({ message: '', open: false });
            }
        }
        else if (cart[0]?.chargeOptionId !== currCart[0]?.chargeOptionId) {
            setState({ message: 'Added to Cart', open: true });
        }

    }, [cart]);

    return (
        state.open && (
            <div>
                <Snackbar open={state.open} autoHideDuration={4000} className={classes.snackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                >
                    <MuiAlert severity="success" onClose={handleClose}>
                        {state.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        )
    )
}


const useStyles = makeStyles((theme) => ({
    snackbar: {
        "& .MuiAlert-standardSuccess": {
            backgroundColor: '#3699ef',
            color: '#fff',
        },
        "&  .MuiAlert-icon": {
            color: '#fff',
        }
    },

}));