import styled from 'styled-components';

/**
 * @todo Wrapper any container page
 * with the @Page pass @backgorund if need
 * useing  @param `background`
 */

export const Page = styled.div`

    background-image:url(${p => p.background ? p.background : ''});
    opacity: 1;
    animation-name: fadeInOpacity;
    /* animation-iteration-count: 1; */
    animation-timing-function: ease-in;
    animation-duration: 1.2s;
    

    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
export const FadeSection = styled.div`
    opacity: 1;
    animation-name: fadeInOpacity;
    /* animation-iteration-count: 1; */
    animation-timing-function: ease-in;
    animation-duration: 1s;

    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

