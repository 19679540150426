import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { client } from "../../config/apolloClient";
import { handleJwt, JWT, useJwt, useUserId } from "../../config/auth";
import { isLoggedInVar } from "../../config/cache";
import { history } from "../../../App";


// handle Logout and remove all information from the local storage
export const useLogout = () => {
  const [setJwt, getJwt, removeJwt, updateJwt] = useJwt();
  const apolloClient = useApolloClient();
  const history = useHistory();

  const logout = async () => {
    // await apolloClient.clearStore();
    // we remove all information in the store
    // const all = await apolloClient.cache.gc();
    // client.cache.evict({ fieldName: 'profile' })
    apolloClient.clearStore();
    client.cache.gc();

    await removeJwt();
    window.location = '/';
    // history.push('/')

    // clear cache
    isLoggedInVar(false)


  }
  return logout;
}

// Logout without hook
export const handleLogoutWithoutHook = async () => {
  const [, , removeJwt,] = handleJwt()
  client.resetStore();
  removeJwt()
};
