import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import footerLogo from "../../assets/images/footerLogo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    width: "100%",
    bottom: "0",
  },
  root: {
    backgroundColor: "#000215",
    maxWidth: "100%",
  },
  rootBox: {
    "& p": {
      color: '#7e7e7e',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  footerNav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginRight: 'auto',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(0),


    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 'auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    }
  },
  footerLink: {
    color: '#7e7e7e',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',

    "&:hover": {
      cursor: "pointer",
    },

    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    }
  },
}));

const FooterSecondary = (props) => {
  const classes = useStyles();

  const content = {
    'brand': { image: footerLogo, width: 110 },
    'copy': 'Copyright © 2020 Stackwiser Ltd. All rights reserved',
    ...props.content
  };
  const links = {
    'Dashboard': '/account',
    'Privacy Policy': '/privacy-policy',
    'Terms of Use': '/terms-and-conditions',
    'Contact Us': '/contact-us',
  };

  let brand;

  if (content.brand.image) {
    brand = <img src={content.brand.image} alt="" width={content.brand.width} />;
  } else {
    brand = content.brand.text || '';
  }

  return (
    <footer className={classes.footer}>
      <Container className={classes.root}>
        <Box py={6} display="flex" flexWrap="wrap" alignItems="center" className={classes.rootBox}>
          <Link href="/" color="inherit" underline="none">
            {brand}
          </Link>
          <Box component="nav" className={classes.footerNav}>
            {Object.keys(links).map((link, i) => (
              <Link href={links.link} variant="body1" key={i} color="textPrimary" className={classes.footerLink}>{link}</Link>
            ))}

          </Box>
          <Typography color="textSecondary" component="p" variant="caption" gutterBottom={false}>{content['copy']}</Typography>
        </Box>
      </Container>
    </footer>
  );
}

export default FooterSecondary;
