let env = process.env.NODE_ENV || "development",
  APP_URL = process.env.REACT_APP_UR || "https://www.stackwisr.com",
  CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV || "local",
  API_URL = process.env.REACT_APP_API_URL || "https://api.stackwisr.com",
  PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID,
  STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY,
  SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS,
  LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID,
  API_UPLOAD_URL = process.env.REACT_APP_API_UPLOAD_URL;

const APPLICATION_ENVS = {
  LOCAL: "local",
  PROD: "prod",
  PREPROD: "preprod",
  STAGING: "staging",
  DEV: "dev",
};

// const sentry = "https://c48b2a1cf88e4515a1528d7567e10bc4@o546844.ingest.sentry.io/5668708",
//   logrocket = "cworhl/stackwisr";

// if ("development" === env) {
//   APP_URL = "https://www.stackwisr.com";
//   API_URL = "https://api-staging.stackwisr.com";
//   STRIPE_KEY =
//     "pk_test_51HlsTEKzQEPt1AeACAUUOcKinqFvzZgIp6zqLw3IM67jaDuRz368XW2Y5MghCSjOhUkt2QYlGXhYUQmrcPAeAsnN00Z4Nl358v";
//   SENTRY_DNS = sentry;
//   LOGROCKET_ID = logrocket;
//   CURRENT_ENV = "local";
//   API_UPLOAD_URL = "https://storage-staging.stackwisr.com/uploads/";
// } else {
//   if (CURRENT_ENV === APPLICATION_ENVS.PROD || CURRENT_ENV === APPLICATION_ENVS.PREPROD) {
//     APP_URL = "https://www.stackwisr.com";
//     API_URL = "https://api.stackwisr.com";
//     STRIPE_KEY =
//       "pk_live_51HlsTEKzQEPt1AeA1tmYrY5LoCr2iNQ10hYBVMNwXZbdpyTY8DfNjBxLLyVldkltobQZOxaSdIfxCxPkk447ZQyP00OFZ8Bise";
//     SENTRY_DNS = sentry;
//     LOGROCKET_ID = logrocket;
//     API_UPLOAD_URL = "https://storage.stackwisr.com/uploads/";
//   } else if (CURRENT_ENV === APPLICATION_ENVS.STAGING) {
//     APP_URL = "https://staging.stackwisr.com";
//     API_URL = "https://api-staging.stackwisr.com";
//     STRIPE_KEY =
//       "pk_test_51HlsTEKzQEPt1AeACAUUOcKinqFvzZgIp6zqLw3IM67jaDuRz368XW2Y5MghCSjOhUkt2QYlGXhYUQmrcPAeAsnN00Z4Nl358v";
//     SENTRY_DNS = sentry;
//     LOGROCKET_ID = logrocket;
//     API_UPLOAD_URL = "https://storage-staging.stackwisr.com/uploads/";
//   } else if (CURRENT_ENV === APPLICATION_ENVS.DEV) {
//     APP_URL = "https://dev.stackwisr.com";
//     API_URL = "http://165.22.123.62:8443";
//     STRIPE_KEY =
//       "pk_test_51HlsTEKzQEPt1AeACAUUOcKinqFvzZgIp6zqLw3IM67jaDuRz368XW2Y5MghCSjOhUkt2QYlGXhYUQmrcPAeAsnN00Z4Nl358v";
//     SENTRY_DNS = sentry;
//     LOGROCKET_ID = logrocket;
//     API_UPLOAD_URL = "https://storage-staging.stackwisr.com/uploads/";
//   }
// }

module.exports = {
  NODE_ENV: env,
  API_URL,
  PAYPAL_CLIENT_ID,
  STRIPE_KEY,
  SENTRY_DNS,
  LOGROCKET_ID,
  CURRENT_ENV,
  APPLICATION_ENVS,
  APP_URL,
  API_UPLOAD_URL,
};
