import * as Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { handleJwt, JWT } from "./auth";
import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getNewToken } from '../operations/mutations/getRefreshToken';
import { handleLogoutWithoutHook } from '../operations/mutations/logoutMutation';

//env
import { CURRENT_ENV, APPLICATION_ENVS } from '../../app.config';


// error codes
const checkoutErrorCodes = ['SUBSCRIPTION_ALREADY_EXISTS', 'MULTIPLE_CURRENCIES_ISSUE', 'INVALID_COUPON', 'INVALID_CHARGE_OPTION', 'INVALID_PRODUCT', 'UNKNOWN'];
const errorIgnore = [...checkoutErrorCodes, 'NOT_FOUND', 'USER_NOT_FOUND', 'PASSWORD_RESET_404'];

// catergorise the error
function errCategorize(errCode) {
    if (errorIgnore.includes(errCode)) {
        return 'IGNORE';
    }
    return errCode;
}

// handle error 

export const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {

        if (CURRENT_ENV === APPLICATION_ENVS.local || CURRENT_ENV === APPLICATION_ENVS.dev) {
            console.log(graphQLErrors)
        }
        graphQLErrors.map((err) => {
            const errCode = errCategorize(err?.extensions?.code)
            switch (errCode) {
                // case 'INVALID_CRED':
                // // DO NOTHING
                // case '_UNAUTHENTICATED':
                //     handleUnauthenticated(operation, forward)
                // case "UNVERIFIED":
                //     window.location = `/login?verified=0`;
                //     break;
                // case "CLOSED":
                //     // window.location = `/login/reactivate`;
                //     break;
                // case "IGNORE":
                // break;
                default:
                    Sentry.captureMessage(err)
                    console.log(graphQLErrors, operation)
            }
        });
    }
    if (networkError) {
        console.log("👮🏻‍♀️ [Network error]:", JSON.stringify(networkError), " ", networkError.statusCode)
        if (!window.location.pathname.includes(networkError?.statusCode)) {
            switch (networkError.statusCode) {
                // case 400:
                //     window.location = `/400`;
                //     break;
                // case 401:
                //     window.location = `/401`;
                //     break;
                // case 403:
                //     window.location = `/403`;
                //     break;
                // case 404:
                //     window.location = `/404`;
                //     break;
                // case 500:
                //     window.location = `/500`;
                //     break;
                // case 502:
                //     window.location = `/502`;
                //     break;
                // case 503:
                //     window.location = `/503`;
                //     break;
                // case 504:
                //     window.location = `/504`;
                //     break;
                default:
                    Sentry.captureException(networkError)
                // history.push(`/`);
            }
        }
    };
})




let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
};

function handleUnauthenticated(operation, forward) {
    // error code is set to UNAUTHENTICATED
    // when AuthenticationError thrown in resolver
    let forward$;
    console.log(isRefreshing)
    if (!isRefreshing) {
        isRefreshing = true;
        console.log(isRefreshing)
        forward$ = fromPromise(
            getNewToken(Cookies)
                .then((token) => {
                    //  update token in cookie
                    const [, , , updateJwt] = handleJwt();
                    updateJwt({ token: token })

                    //  update token in header
                    const oldHeaders = operation.getContext().headers;
                    console.log(oldHeaders)
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `Bearer ${token}`,
                        },
                    });
                    resolvePendingRequests();
                    return token;
                })
                .catch(error => {
                    pendingRequests = [];
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    handleLogoutWithoutHook()
                    window.location = '/signin';
                    // history.go()

                    return;
                })
                .finally((value) => {
                    console.log(value)
                    const oldHeaders = operation.getContext().headers;
                    console.log(JSON.parse(Cookies.get(JWT)).token)
                    console.log(oldHeaders)
                    isRefreshing = false;
                })
        ).filter(value => Boolean(value));
    } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
            new Promise(resolve => {
                pendingRequests.push(() => resolve());
            })
        );
    }

    return forward$.flatMap(() => { forward(operation) });
}