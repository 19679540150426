import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//components
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useLogout } from "../../graphql/operations/mutations/logoutMutation";
import { Fade, useMediaQuery } from "@material-ui/core";

//helpers
import { spaceRemove } from "../../globalHelpers/routeHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: '0 5px',

    ["@media screen and (max-width : 960px)"]: {
      margin: '0',
      width: '100%',
    },

    "& button": {
      color: "white",
    },
  },
  routeBtn: {
    "& .MuiAvatar-root": {
      marginRight: '10px',
    }
  },
  dropdownWrapper: {

  },
  navMenu: {
    backgroundColor: "#313142",
    color: "white",
    minWidth: '190px',
    width: 'max-content',
    maxWidth: '300px',
    // maxWidth: 'max-content',
    borderRadius: '4px',
    padding: '0',
    boxShadow: '0px 0px 9px 0px #16152570',

    ["@media screen and (max-width : 960px)"]: {
      maxWidth: '100%',
      width: '100%',

    },

    "& li": {
      padding: "6px 30px",
      padding: "10px 30px",
      fontSize: '0.9rem',
      whiteSpace: 'break-spaces',
      fontFamily: 'unset',
      // letterSpacing: '0.4px',
      textTransform: 'none',
      "&:hover": {
        backgroundColor: "#16152542",
      },
    },

  },
  paper: {
    marginRight: theme.spacing(2),
  },
  subLink: {
    padding: "0 !important",
    color: "#fff",
    textDecoration: "none",
  }
}));

const variants = {
  MENU: 'menu',
  LINK: 'link',
  LINK_MENU: 'link-menu'
}

export default function MainMenu({
  title,
  icon,
  variant = variants.MENU,
  linkProps: {
    to = '#',
    className: linkPropClassName = '',
    ...linkPropsRest
  } = {},
  menuProps: {
    data,
    loading,
    error,
    type = '',
  } = {}
}) {
  const classes = useStyles();
  const theme = useTheme();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);


  const handleToggle = (event) => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  };

  const handleEnter = (event) => {
    setOpen(true)
  };

  const handleLeave = (event) => {
    setOpen(false)
  };

  const handleClose = (event) => {
    // setAnchorEl(null);
  };

  const handleChange = (event, link) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }
    handleClose();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      handleToggle(event);
    }
  }



  //Logout logic
  const logout = useLogout();
  const handleLogout = (e) => {
    logout();
  }


  return (
    <div className={classes.root}
      onMouseEnter={() => [variants.MENU, variants.LINK_MENU].includes(variant) && !mobileView && handleEnter()}
      onMouseLeave={() => [variants.MENU, variants.LINK_MENU].includes(variant) && !mobileView && handleLeave()}
    >
      {variant === variants.LINK ? (
        <>
          <Button
            {...linkPropsRest}
            id={`spaceRemove(title, '-')`}
            className={`${classes.routeBtn} ${linkPropClassName}`}
            component={Link}
            to={to}
          >
            {icon}
            <p>{title}</p>
          </Button>
        </>
      ) : variant === variants.MENU ? (
        <>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            id={`menu-${type !== 'account' ? spaceRemove(title, '-') : 'account'}`}
            onClick={handleToggle}
            className={classes.routeBtn}

          >
            {icon}
            <p>{title}</p>
            < KeyboardArrowDownIcon />
          </Button>

          <PopperWrapper
            data={data}
            type={type}
            loading={loading}
            error={error}
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            onMenuClicked={handleChange}
            onLogout={handleLogout}
            onListKeyDown={handleListKeyDown}
          />

        </>
      ) : variant === variants.LINK_MENU && (
        <>
          <Button
            {...linkPropsRest}
            component={Link}
            to={to}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            id={`menu-${type !== 'account' ? spaceRemove(title, '-') : 'account'}`}
            onClick={mobileView ? handleToggle : () => { }}
            className={classes.routeBtn}

          >
            {icon}
            <p>{title}</p>
            < KeyboardArrowDownIcon />
          </Button>

          <PopperWrapper
            data={data}
            type={type}
            loading={loading}
            error={error}
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            onMenuClicked={handleChange}
            onLogout={handleLogout}
            onListKeyDown={handleListKeyDown}
          />

        </>
      )
      }

    </div>
  );
}

MainMenu.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(variants)).isRequired,
  icon: PropTypes.any,
  linkProps: PropTypes.shape({
    to: PropTypes.string.isRequired
  }),
  menuProps: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      onClick: PropTypes.func,
    })).isRequired,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(["account", ""]),
    error: PropTypes.shape({
      message: PropTypes.any
    }),
  }),
}


const PopperWrapper = ({ data, loading, error, type, open, anchorEl, onClose, onListKeyDown, onMenuClicked, onLogout }) => {
  const classes = useStyles();
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      role={undefined}
      transition
      className={`${classes.dropdownWrapper} maindrdwWr`}
      placement={type === 'account' ? "bottom-end" : 'bottom'}
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: false,
        },
        offset: {
          enabled: true,
          offset: type === 'account' ? '0,14' : '0,18',
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: false,
          element: false,
        },
      }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper >
            <ClickAwayListener onClickAway={onClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                className={classes.navMenu}
                onKeyDown={onListKeyDown}
              >


                {data?.map((content, index) => {
                  if (loading) return 'Loading...';
                  if (error) return `Error! ${error.message}`;
                  return (
                    content?.link || content?.route ? (
                      <Link to={content.link ? content.link : content.route} className={classes.subLink} key={index}
                        id={`sub-${spaceRemove(content.title, '-')}`}
                      >
                        <MenuItem
                          id={`sub-item--${spaceRemove(content.title, '-')}`}
                          onClick={(e) => onMenuClicked(e)}
                        >
                          {content.title}
                        </MenuItem>
                      </Link>
                    ) : content?.onClick && (
                      <MenuItem
                        key={index}
                        id={`sub-item--${spaceRemove(content.title, '-')}`}
                        onClick={(e) => {
                          onMenuClicked(e)
                          content.onClick(content.title)
                        }}
                      >
                        {content.title}
                      </MenuItem>
                    )
                  )
                })}
                {type === 'account' && (
                  <MenuItem
                    id='sub-item--logout'
                    onClick={(e) => onLogout(e)}
                  >
                    Logout
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

