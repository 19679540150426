import React from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, Snackbar } from "@material-ui/core";
import { useReactiveVar } from '@apollo/client';
import { alertMessageVar } from '../../graphql/config/cache';
import { alertTypes } from '../../Constants/uiConstants';


export default function AlertMessage() {
    const classes = useStyles();
    const [state, setState] = React.useState(false);
    const messageData = useReactiveVar(alertMessageVar)

    const handleClose = (event, reason) => {
        // if (reason === "clickaway") {
        //     return;
        // }
        setState(false);
    };

    React.useEffect(() => {
        setState(true);
        const timer = setTimeout(async () => {
            await alertMessageVar(undefined)
        }, 8000)
        return () => clearTimeout(timer);
    }, [messageData]);


    return (
        messageData ? (
            <div>
                <Snackbar open={state}
                    autoHideDuration={5000}
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={handleClose}
                >
                    <MuiAlert severity={messageData?.severity || alertTypes.INFO}
                        onClose={handleClose}>
                        {messageData?.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        ) : null
    )
}


const useStyles = makeStyles((theme) => ({
    snackbar: {
        "& .MuiAlert-standardSuccess": {
            backgroundColor: '#3699ef',
            color: '#fff',
            "&  .MuiAlert-icon": {
                color: '#fff',
            }
        },
    },

}));