import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

//components
import { Button } from "@material-ui/core";

//assets
import bg from '../../assets/images/404/404.png'
import { NetworkErrors } from "../utilites/constants";
import EmptyCardMessage from "../emptyCardMessage/EmptyCardMessage";



const NotFound = ({
  type = 'error',
  message,
  button: {
    message: btnMessage = "",
    ...btnRest
  } = {}
}) => {
  return (
    <Wrapper>
      <div className="_err__inner">
        <div className="err__imgWrapepr">
          {type === 'error' ? (
            <>
              <EmptyCardMessage
                message={""}
                type='error'
                style={{
                  width: 300,
                  height: 300
                }}
              />
            </>
          ) : (
            <img src={NetworkErrors[type]?.image || NetworkErrors[500].image} alt={type} />
          )}
        </div>
        <div className="_err__content">

          <h1>{type}</h1>
          <div className="_404__lower">
            <p>{message || NetworkErrors[type].message}</p>
          </div>
          <Button href={NetworkErrors[type]?.link?.url} className="_404__btn" {...btnRest} id='action'>{btnMessage || NetworkErrors[type]?.link?.message}</Button>
        </div>
      </div>
    </Wrapper>
  );
};

NotFound.propTypes = {
  type: PropTypes,
  type: PropTypes.oneOf(['404', '400', '401', '404', '500', '502', '503', '504', '403', 'error'])
}

export default NotFound;




const Wrapper = styled.div`
  background:#161525;
  background-size:cover;
  min-height:80vh;
  position: fixed;
  width: 100%;
  height:100%;
  display:flex;
  flex-direction:column;
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.43'%3E%3Cpolygon fill='%23272839' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%231d1e30' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23141527' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%230a0b1e' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23000215' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
  background-position: 100% 0px;
  overflow-x:hidden;
  z-index: 2;
 
  

  ._err__inner{
    /* margin:auto; */
    margin-top: 123px;
    align-self: center;
    display:flex;
    flex-direction:row-reverse;
    align-items: center;
    color:white;
    /* background: #313142 0% 0% no-repeat padding-box; */
    opacity:0.8;
    max-width: 750px;
    border-radius:4px;
    position:relative;

    @media screen and (max-width:545px){
      flex-direction:column;
    }

    .err__imgWrapepr{
      width:50%;
      img{
        width:300px;
        filter: drop-shadow(34px 7px 5px #0000002e);
      }  
      
      @media screen and (max-width:650px){
        width:40%;
        img{
          width:220px;
        } 
      }
      @media screen and (max-width:550px){
        width:100%;
        text-align:center;
        img{
          width: 154px;
          transform: rotate(49deg);
          position: absolute;
          bottom: -59px;
          right: 5px;
        } 
      }
      @media screen and (max-width:445px){
        display:none;
      }
    }
    
    ._err__content{
      margin-right: auto;
      padding: 10% 0 10% 10%;
      /* width:50%; */
      
      h1{
        font-size:60px;
        color:white;
        margin:0;
        text-transform: capitalize;
      }
      p{
        font-size:20px;
        font-size: calc(16px + 0.5vw);
        margin:0;
      }
      @media screen and (max-width:650px){
        width:60%;
        h1{
        font-size:60px;
        }
        p{
          font-size:16px;
        }
      }
      @media screen and (max-width:545px){
        margin:auto;
        text-align:center;
        padding:10%
      }

    }
    p{
      font-size:17px;
      margin-top:20px;
      font-size: calc(calc(17px + 1vw) - 11px);
    }
  
}
  ._404__btn{
    margin-top:35px;
    background-color:transparent;
    /* border: 2px solid #CBC2C2; */
    border-radius: 4px;
    color:white;
    width: 200px;
    height: 42px;
    font-size:13px;
    transition:all 0.35 ease;
    box-shadow: 0px 0px 0.3px 4px #ffffff24;

    
  }
  ._404__btn:hover{
    background-color: #2196f3;
    color: #fff;

  }
  @media screen and (max-width:650px){
    ._404__btn{
      font-size:11px;
      width:130px;
    }
  }

.lottie-animation{
  background-color: red;
}
`;

