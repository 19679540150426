/**
 * 
 * @param {String} title -- title 
 * @param {String} leading  -- leading route to append `stack/demo`
 * @param {String} after  -- end of route to append `` optional 
 * @return {string} -- `stack/demo/${title}/ending`
 */

export function createRoute(title, leading, after = '') {
    const route = `${leading}${title?.replace(/[\s+/]/g, '-').toLowerCase()}${after}`;
    return route;
}

/**
 * 
 * @param {*} str - string to remove spacing
 * @param {*} replace  what to replace space with
 * @returns  modified string
 */
export const spaceRemove = (str, replace = '') => {
    return str.replace(/\s+/g, `${replace}`).toLowerCase()
}

/**
 * 
 * @param {*} urlString -- string 
 * @param {*} split  -- separator
 * @returns 
 */
export const getQueryString = (urlString, split = "&") => {
    return urlString
        .substring(1)
        .split(split)
        .reduce((initial, item) => {
            let parts = item.split('=');
            initial[parts[0]] = decodeURIComponent(parts[1]);

            return initial
        }, {});
}